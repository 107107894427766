<template>
  <div class="Tree">
    <div class="flex_s" >
      <div class="treebox" style="background: #ad001a"></div>
      <span>申请次数</span>
      <div class="treebox" style="background: #d6d6d6"></div>
      <span>申请机构数</span>
    </div>
    <div class="treeview flex_V">
      <div class="flex_V trees" v-for="(item, index) in newList" :key="index">
        <div
          class="frequency"
          :style="{ height: (item.freheight / height.zs) * 200 + 'px' }"
        >
          <div style="text-align: center" class="Percentage">
            {{ item.freheight }}
          </div>
        </div>
        <div
          class="institutions"
          :style="{ height: (item.instheight / height.zs) * 200 + 'px' }"
        >
          <div style="text-align: center" class="Percentage">
            {{ item.instheight }}
          </div>
        </div>
      </div>
    </div>
    <div class="flex_b">
      <div style="width: 50px; text-align: center">15天</div>
      <div style="width: 50px; text-align: center">1个月</div>
      <div style="width: 50px; text-align: center">3个月</div>
      <div style="width: 50px; text-align: center">6个月</div>
      <div style="width: 50px; text-align: center">12个月</div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    list: Array,
  },
  data() {
    return {
      treeleng: 200,
    };
  },
  mounted() {
   
  },
  computed: {
    newList({ list }) {
      return list;
    },
    height({ list }) {
      let lists = list;
      let from = {
        frenum: 0,
        instnum: 0,
        zs: 0,
      };
      lists.forEach((res) => {
        if (res.freheight > from.frenum) {
          from.frenum = res.freheight;
        }
        if (res.instheight > from.instnum) {
          from.instnum = res.freheight;
        }
        if (from.frenum > from.instnum) {
          from.zs = from.frenum;
        } else {
          from.zs = from.instnum;
        }
      });
      return from;
    },
  },
};
</script>

<style lang="less">
.flex_V {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}
.treebox {
  width: 16px;
  height: 8px;
  margin: 0 10px;
  // background: #ad001a;
  opacity: 1;
  border-radius: 0px;
}
.Tree {
  width: 95%;
  margin: 10px auto;
  background: #ffffff;
  //   background: pink;
}
.Percentage {
  position: relative;
  bottom: 20px;
}
.treeview {
  width: 100%;
  height: 230px;
  border-bottom: 1px solid #efecec;
  //   padding-bottom: 10px;
  .trees {
    height: 100%;
    .frequency {
      width: 25px;
      transition: 0.5s all;
      color: #ad001a;
      background: #ad001a;
    }
    .institutions {
      width: 25px;
      margin: 0 5px;
      color: #a3a1a1;
      transition: 0.5s all;
      background: #d6d6d6;
    }
  }
}
</style>