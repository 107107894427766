<template>
  <div class="body" v-if="bodystatus == true">
    <div class="lianjie" @click="lianjie" :data-clipboard-text="textUrl" data-clipboard-action="copy">生成链接</div>
    <header class="header">
      <div class="flex_b">
        <div>
          <div class="text">信用分数</div>
          <div class="font">
            {{ myMixintable.线下消费分期分数 == "" ? "无" : myMixintable.线下消费分期分数}}
          </div>
        </div>
        <div>
          <div class="text">反欺诈分数</div>
          <div class="font">
            {{ myMixintable.反欺诈评分 == "" ? "0" : myMixintable.反欺诈评分 }}
          </div>
        </div>
        <img class="icon" :src="Image" />
      </div>
      <div>
        <span class="wenzi"
        >信用分数：300-1000分，分数越高代表客户偿还能力越好，违约概率越小。无分数：不符合评分规则</span>
      </div>
      <span class="wenzi"
        >反欺诈分数：<span  :class=" myMixintable.反欺诈评分 <= 60?'color':'while'">低风险0-60，</span><span   :class=" myMixintable.反欺诈评分 >= 61 && myMixintable.反欺诈评分 <= 80?'color':'while'">中风险61-80，</span><span   :class=" myMixintable.反欺诈评分 >= 81 && myMixintable.反欺诈评分 <= 100?'color':'while'">高风险81-100</span></span
      >
    </header>
    <div class="Contents">
      <div>
        <div class="information">
          <div class="form">
            <h3>基本信息</h3>
            <div class="boxs">
              <label for="name" class="title">您的名字:</label>
              <span id="name" class="content">{{ myMixintable.姓名 }}</span>
            </div>
            <div class="boxs">
              <label for="name" class="title">手机号:</label>
              <span id="name" class="content">
                *********{{ myMixintable.手机号 }}</span
              >
            </div>
            <div class="boxs">
              <label for="name" class="title">身份证号:</label>
              <span id="name" class="content"
                >*******************{{ myMixintable.身份证号 }}</span
              >
            </div>
            <div class="boxs">
              <label for="name" class="title">报告编号:</label>
              <span id="name" class="content">{{ myMixintable.报告编号 }}</span>
            </div>
            <div class="boxs">
              <label for="name" class="title">生成时间:</label>
              <span id="name" class="content">{{ myMixintable.生成时间 }}</span
              ><span class="content red">（7天有效）</span>
            </div>
          </div>
        </div>
        <div class="formbox">
          <div class="head flex_c">
            <div class="red">—</div>
            <div class="text_C margin">运营商核验 - 详情</div>
            <div class="red">—</div>
          </div>
          <form v-for="(item, index) in Operator" :key="index">
            <div class="title">
              <h3>{{ item.name_rule }}</h3>
            </div>
            <div class="flex_b content">
              <div class="flex">
                <div style="width: 90px" v-show="operation != {}">
                  运营商:{{
                    operation.operation == 1
                      ? "电信"
                      : operation.operation == 2
                      ? "联通"
                      : operation.operation == 3
                      ? "移动"
                      : operation.operation == 4
                      ? "其他运行商"
                      : ""
                  }}
                </div>
                <div style="">{{ item.Phone }}:{{ item.costTime }}</div>
              </div>
              <div
                class="label"
                v-if="item.verdict == '低风险'"
                style="background: #eefaec; color: #35ad00"
              >
                低风险
              </div>
              <div
                class="label"
                v-else-if="item.verdict == '中风险'"
                style="background: #ffe9d3; color: #ad0000"
              >
                中风险
              </div>
              <div
                class="label"
                v-else-if="item.verdict == '高风险'"
                style="background: #ffeaed; color: #ad001a"
              >
                高风险
              </div>
            </div>
            <div v-if="item.text != undefined" class="text">
              {{ item.text }}
            </div>
          </form>
        </div>
        <div class="formbox">
          <div class="head flex_c" style="background:#ad001a;color:#ffeaed">
            <div class="red">—</div>
            <div class="text_C margin" style="">规则风险提示<span style="font-size:13px">(总风险{{from.value6.length+from.value1.length+from.value2.length+from.value3.length}}条)</span></div>
            <div class="red">—</div>
          </div>
          <div style="width: 90%; margin: 10px auto">
            <div class="">
              <div  class="flex_b">
              <span style="font-weight:570"><h3>运营商核验</h3></span>
              <div class="flex_e">
                   <span>该产品类命中<span style="color:#ad0000">{{ from.value6.length }}</span>条高风险</span>
              </div>
              </div>
              <div>
                <div style="font-size: 14px; font-weight: 400">
                  策略描述：查询被查询人的运营商信息
                </div>
                <div style="font-size: 14px; font-weight: 400">
                  结论分析：
                  <div class="flex_b" style="margin:15px 0;font-weight:570" v-show = "Operator[0].verdict !='低风险'">
                    <div>手机在网时长</div>
                    
                    <div
                      class="label"
                      style="
                        background: #eefaec;
                        color: #35ad00;
                        margin: 0 10px;
                      "
                    >
                      {{ Operator[0].verdict =="低风险"?vatlur:vatlur}}
                    </div>
                  </div>
                  <div class="flex_b"  style="margin:15px 0;font-weight:570" v-show = "Operator[0].verdict !='低风险'">
                    <div>手机信息验证</div>
                    <div
                      class="label"
                      style="
                        background: #eefaec;
                        color: #35ad00;
                        margin: 0 10px;
                      "
                    >
                      {{ Operator[1].verdict =="低风险"?vatlur:vatlur}}
                    </div>
                  </div>
                  <div class="flex_b"  style="margin:15px 0;font-weight:570" v-show = "Operator[0].verdict !='低风险'">
                    <div>身份证信息验证</div>
                    <div
                      class="label"
                      style="
                        background: #eefaec;
                        color: #35ad00;
                        margin: 0 10px;
                      "
                    >
                      {{ Operator[2].verdict =="低风险"?vatlur:vatlur}}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div style="margin: 20px 0">
              <div class="flex_b" style="margin: 15px 0">
            <span style="font-weight:570"><h3>风险识别</h3></span>
                <span>该产品类命中<span style="color:#ad0000">{{ from.value1.length }}</span>条高风险</span>
              </div>
              <div style="font-size: 14px; font-weight: 400">
                策略描述：检测被查询人的借贷风险情况，及在司法体系中是否存失信行为等风险
              </div>
              <div class="" style="font-size: 14px; font-weight: 400">
                <div class="flex_s">结论分析:</div>
                <div class="flex_b" style="margin: 15px 0" >
                  <div
                    style="font-size: 12px; font-weight: 600" v-show=" myMixintable.法院被执行人限高产品风险结果.verdict =='高风险' && myMixintable.法院被执行人限高产品风险结果.verdict != undefined"
                  >
                    法院被执行人 - 限高被执行人
                  </div>
                  <div v-if="Executor.length != 0">
                    <div
                      class="label"
                      v-if="
                        myMixintable.法院被执行人限高产品风险结果.verdict ==
                        '高风险'
                      "
                      style="background: #ffeaed; color: #ad001a"
                    >
                      高风险
                    </div>
                    <div
                      class="label"
                      v-else-if="
                        myMixintable.法院被执行人限高产品风险结果.verdict ==
                        '中风险'
                      "
                      style="background: #eefaec; color: #35ad00"
                    >
                      中风险
                    </div>
                    <div
                      class="label"
                      v-else-if="
                        myMixintable.法院被执行人限高产品风险结果.verdict ==
                        '低风险'
                      "
                      style="background: #ffe9d3; color: #ad0000"
                    >
                     低风险
                    </div>
                  </div>
                </div>
                <div class="flex_b" style="margin: 15px 0" >
                  <div
                  
                    style="font-size: 12px; font-weight: 600" v-show=" myMixintable.法院判决文书产品风险结果.verdict =='高风险' && myMixintable.法院判决文书产品风险结果.verdict != undefined"
                  >
                    法院裁判文书
                  </div>
                  <div
                    class="label"
                    v-if="
                      myMixintable.法院判决文书产品风险结果.verdict == '高风险'
                    "
                    style="background: #ffeaed; color: #ad001a"
                  >
                    高风险
                  </div>
                   <div
                      class="label"
                      v-else-if="
                        myMixintable.法院判决文书产品风险结果.verdict ==
                        '中风险'
                      "
                      style="background: #eefaec; color: #35ad00"
                    >
                      中风险
                    </div>
                    <div
                      class="label"
                      v-else-if="
                        myMixintable.法院判决文书产品风险结果.verdict ==
                        '低风险'
                      "
                      style="background: #ffe9d3; color: #ad0000"
                    >
                      {{vatlur}}
                    </div>
                </div>
                <div class="flex_b" style="margin: 15px 0" >
                  <div
                  
                   style="font-size: 12px; font-weight: 600" v-show=" myMixintable.法院信息产品风险结果.verdict =='高风险' && myMixintable.法院信息产品风险结果.verdict != undefined"
                  >
                    法院信息详情
                  </div>
                  <div v-if="Dishonestperson.length != 0">
                    <div
                      class="label"
                      v-if="
                        myMixintable.法院信息产品风险结果.verdict == '高风险'
                      "
                      style="background: #ffeaed; color: #ad001a"
                    >
                      高风险
                    </div>
                    <div
                      class="label"
                      v-else-if="
                        myMixintable.法院被执行人限高产品风险结果.verdict ==
                        '中风险'
                      "
                      style="background: #eefaec; color: #35ad00"
                    >
                      中风险
                    </div>
                    <div
                      class="label"
                      v-else-if="
                        myMixintable.法院被执行人限高产品风险结果.verdict ==
                        '低风险'
                      "
                      style="background: #ffe9d3; color: #ad0000"
                    >
                    低风险
                    </div>
                  </div>
                </div>
                <div class="flex_b" style="margin: 15px 0" >
                  <div
                   style="font-size: 12px; font-weight: 600"    v-show="myMixintable.特殊名单验证产品详情 !=undefined && myMixintable.特殊名单验证产品详情.verdict !='低风险'"
                  >
                    特殊名单
                  </div>
                  <div
                    v-if="order !=[]"
                    style="font-size: 10px; font-weight: 600; margin: 0 10px" 
                  >
                    <div
                      class="label"
                      v-if="
                        myMixintable.特殊名单验证产品风险结果.verdict ==
                        '高风险'
                      "
                      style="background: #ffeaed; color: #ad001a"
                    >
                      高风险
                    </div>
                    <div
                      class="label"
                      v-else-if="
                        myMixintable.特殊名单验证产品风险结果.verdict ==
                        '中风险'
                      "
                      style="background: #eefaec; color: #35ad00"
                    >
                      中风险
                    </div>
                    <div
                      class="label"
                      v-else-if="
                        myMixintable.特殊名单验证产品风险结果.verdict ==
                        '低风险'
                      "
                      style="background: #ffe9d3; color: #ad0000"
                    >
                      低风险
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div >
              <div class="flex_b">
                  <span style="font-weight:570"><h3>借贷评估</h3></span>
                <span>该产品类命中<span  style="color:#ad0000">{{ from.value2.length }}</span>条高风险</span>
              </div>
              <div></div>
              <div style="font-size: 14px; font-weight: 400">
                策略描述：根据被查询人的借贷信息预测被查询人的风险情况
              </div>
              <div class="" style="font-size: 14px; font-weight: 400">
                <div class="flex_s">结论分析:</div>
                <div class="flex_b" style="margin: 5px 0;font-size: 12px;font-weight: 600;"  v-show="myMixintable.偿债压力指数产品风险结果.verdict!='低风险' && myMixintable.偿债压力指数产品风险结果.verdict!=undefined">
                  偿还压力指数
                  <div class="content">
                    <div
                      class="label"
                      v-if="
                        myMixintable.偿债压力指数产品风险结果.verdict ==
                        '高风险'
                      "
                      style="background: #ffeaed; color: #ad001a"
                    >
                      高风险
                    </div>
                    <div
                      class="label"
                      v-else-if="
                        myMixintable.偿债压力指数产品风险结果.verdict ==
                        '低风险'
                      "
                      style="background: #eefaec; color: #35ad00"
                    >
                       {{vatlur}}
                    </div>
                    <div
                      class="label"
                      v-else-if="
                        myMixintable.偿债压力指数产品风险结果.verdict ==
                        '中风险'
                      "
                      style="background: #ffe9d3; color: #ad0000"
                    >
                      中风险
                    </div>
                    <div
                      class="label"
                      v-else
                      style="background: #eefaec; color: #35ad00"
                    >
                       {{vatlur}}
                    </div>
                  </div>
                </div>
                <div class="flex_b" style="margin: 5px 0;font-size: 12px;font-weight: 600;"  v-show="myMixintable.团伙欺诈排查产品风险结果.verdict!='低风险' && myMixintable.团伙欺诈排查产品风险结果.verdict!=undefined">
                  团伙欺诈排查
                  <div
                    class="content"
                    v-if="myMixintable.团伙欺诈排查产品风险结果 != undefined"
                  >
                    <div
                      class="label"
                      v-if="
                        myMixintable.团伙欺诈排查产品风险结果.verdict ==
                        '高风险'
                      "
                      style="background: #ffeaed; color: #ad001a"
                    >
                      高风险
                    </div>
                    <div
                      class="label"
                      v-else-if="
                        myMixintable.团伙欺诈排查产品风险结果.verdict ==
                        '中风险'
                      "
                      style="background: #ffe9d3; color: #ad0000"
                    >
                      中风险
                    </div>
                    <div
                      class="label"
                      v-else-if="
                        myMixintable.团伙欺诈排查产品风险结果.verdict ==
                        '低风险'
                      "
                      style="background: #eefaec; color: #35ad00"
                    >
                       {{vatlur}}
                    </div>
                    <div
                      class="label"
                      v-if="
                        myMixintable.团伙欺诈排查产品风险结果.verdict ==
                        undefined
                      "
                      style="background: #eefaec; color: #35ad00"
                    >
                      {{vatlur}}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div style="margin: 20px 0">
              <div class="flex_b">
             <span style="font-weight:570"><h3>要素核查</h3></span>
                <span>该产品类命中<span  style="color:#ad0000">{{from.value3.length}}</span>条高风险</span>
              </div>
              <div style="font-size: 14px; font-weight: 400">
                策略描述：根据被查询人的借贷信息预测被查询人的风险情况
              </div>
              <div class="" style="font-size: 14px; font-weight: 400">
                <div class="flex_s">结论分析:</div>
                <div class="flex_b" style="margin: 5px 0" v-for="(item, index) in details" :key="index" v-show="item.verdict != '低风险'">
                  <div>
                      <div style="font-size:12px;font-weight:600">{{ item.name_rule }}</div>
                    </div>
                    <div class="flex_b content">
                      <div class="flex"></div>
                      <div
                        class="label"
                        v-if="item.verdict == '低风险'"
                        style="background: #eefaec; color: #35ad00"
                      >
                          低风险
                      </div>
                      <div
                        class="label"
                        v-else-if="item.verdict == '中风险'"
                        style="background: #ffe9d3; color: #ad0000"
                      >
                        中风险
                      </div>
                      <div
                        class="label"
                        v-else-if="item.verdict == '高风险'"
                        style="background: #ffeaed; color: #ad001a"
                      >
                        高风险
                      </div>
                    </div>
                </div>
              </div>
            </div>
           
          </div>
        </div>
        <div class="formbox">
          <div class="head flex_c">
            <div class="red">—</div>
            <div class="text_C margin">要素核查 - 命中详情</div>
            <div class="red">—</div>
          </div>
          <div v-if="details != null">
            <form v-for="(item, index) in details" :key="index">
              <div class="title">
                <h3>{{ item.name_rule }}</h3>
              </div>
              <div class="flex_b content">
                <div class="flex">
                  <div>{{ item.Subtitle }}</div>
                  <div style="margin: 0 30px">
                    校验结果：{{ item.weight == 1 ? "一致" : "不一致" }}
                  </div>
                </div>
                <div
                  class="label"
                  v-if="item.verdict == '低风险'"
                  style="background: #eefaec; color: #35ad00"
                >
                  低风险
                </div>
                <div
                  class="label"
                  v-else-if="item.verdict == '中风险'"
                  style="background: #ffe9d3; color: #ad0000"
                >
                  中风险
                </div>
                <div
                  class="label"
                  v-else-if="item.verdict == '高风险'"
                  style="background: #ffeaed; color: #ad001a"
                >
                  高风险
                </div>
              </div>
            </form>
          </div>
        </div>
        <div class="Risk">
          <div class="head flex_c">
            <div class="red">—</div>
            <div class="text_C margin">借贷评估 - 命中详情</div>
            <div class="red">—</div>
          </div>
          <div
            class="assessment flexs"
            v-if="myMixintable.偿债压力指数产品详情 != {}"
          >
            <div class="box1">
              <div class="titles">偿还压力指数</div>
              <div class="zhi">
                {{ myMixintable.偿债压力指数产品详情.nodebtscore }}
              </div>
            </div>
            <div style="width: 80%">
              <div class="flex" style="padding: 0 5px">
                <div style="width: 120px">偿还压力评估</div>
                <div class="content">
                  <div
                    class="label"
                    v-if="
                      myMixintable.偿债压力指数产品风险结果.verdict == '高风险'
                    "
                    style="background: #ffeaed; color: #ad001a"
                  >
                    高风险
                  </div>
                  <div
                    class="label"
                    v-else-if="
                      myMixintable.偿债压力指数产品风险结果.verdict == '低风险'
                    "
                    style="background: #eefaec; color: #35ad00"
                  >
                    低风险
                  </div>
                  <div
                    class="label"
                    v-else-if="
                      myMixintable.偿债压力指数产品风险结果.verdict == '中风险'
                    "
                    style="background: #ffe9d3; color: #ad0000"
                  >
                    中风险
                  </div>
                  <div
                    class="label"
                    v-else
                    style="background: #eefaec; color: #35ad00"
                  >
                    低风险
                  </div>
                </div>
              </div>
              <div style="margin: 5px 0; padding: 0 4px" class="gray">
                注：⽤⼾本⼈当前偿债压⼒指数的情况，取值为0 -
                100，数值越⼤，压⼒越⼤
              </div>
            </div>
          </div>
          <!-- <div v-else style="text-align:center">没有信息哦~</div> -->
          <div
            class="assessment flexs"
            v-if="myMixintable.团伙欺诈排查产品详情 != {}"
          >
            <div class="box1">
              <div class="titles">风险等级</div>
              <div class="zhi">
                {{
                  myMixintable.团伙欺诈排查产品详情.list_level == null
                    ? "无"
                    : myMixintable.团伙欺诈排查产品详情.list_level
                }}
              </div>
            </div>
            <div style="width: 80%">
              <div class="flex" style="padding: 0 5px">
                <div style="width: 120px">团伙欺诈排查</div>
                <div
                  class="content"
                  v-if="myMixintable.团伙欺诈排查产品风险结果 != undefined"
                >
                  <div
                    class="label"
                    v-if="
                      myMixintable.团伙欺诈排查产品风险结果.verdict == '高风险'
                    "
                    style="background: #ffeaed; color: #ad001a"
                  >
                    高风险
                  </div>
                  <div
                    class="label"
                    v-else-if="
                      myMixintable.团伙欺诈排查产品风险结果.verdict == '中风险'
                    "
                    style="background: #ffe9d3; color: #ad0000"
                  >
                    中风险
                  </div>
                  <div
                    class="label"
                    v-else-if="
                      myMixintable.团伙欺诈排查产品风险结果.verdict == '低风险'
                    "
                    style="background: #eefaec; color: #35ad00"
                  >
                    低风险
                  </div>
                  <div
                    class="label"
                    v-if="
                      myMixintable.团伙欺诈排查产品风险结果.verdict == undefined
                    "
                    style="background: #eefaec; color: #35ad00"
                  >
                    低风险
                  </div>
                </div>
              </div>
              <div style="margin: 5px 0; padding: 0 4px" class="gray">
                团伙规模大于等于1人，小于50人
              </div>
            </div>
          </div>

          <div class="gray Tips" style="width: 90%; margin: 0 auto">
            1. 风险等级取值区间为3-10，取值越⼤，风险越⾼；<br />
            2. 团伙规模指同⼀风险等级对应群组规模的取值。
          </div>
        </div>
        <Treeview :list="list" />
        <div class="Risk">
          <div class="Riskcontent" style="margin: 10px 0">
            <div class="flex_c">
              <div
                v-for="(item, index) in Lendingintention"
                :key="index"
                class="flex_b content"
              >
                <h3 class="">借贷意向验证</h3>
                <div v-if="myMixintable.偿债压力指数产品风险结果 != {}">
                  <div
                    class="label"
                    v-if="
                      myMixintable.偿债压力指数产品风险结果.verdict == '高风险'
                    "
                    style="background: #ffeaed; color: #ad001a"
                  >
                    高风险
                  </div>
                  <div
                    class="label"
                    v-else-if="
                      myMixintable.偿债压力指数产品风险结果.verdict == '中风险'
                    "
                    style="background: #ffe9d3; color: #ad0000"
                  >
                    中风险
                  </div>
                  <div
                    class="label"
                    v-else-if="
                      myMixintable.偿债压力指数产品风险结果.verdict == '低风险'
                    "
                    style="background: #eefaec; color: #35ad00"
                  >
                    低风险
                  </div>
                </div>
              </div>
            </div>
            <div
              class="gray"
              style="width: 90%; margin: 0 auto"
              v-if="myMixintable.偿债压力指数产品风险结果 != {}"
            >
              借贷意向数据覆盖⼤部分的⾦融机构，评估个体近7/15天、<br />
              1/3/6/12个⽉的多次申请信贷情况。<br />
              1.机构类型——银⾏：传统银⾏、⽹络零售银⾏；⾮银：持牌⽹络⼩贷、持牌⼩贷、持牌消费⾦融、持牌融资租赁、持牌汽⻋⾦融、其他。<br />
              2.新增业务类型，共8类：信⽤卡（类信⽤卡）、线上现⾦分期、线下现⾦分期、线上⼩额现⾦贷、汽⻋⾦融、线上消费分期、线下消费分期、
              其他。<br />
              3.时间维度定义：近1个⽉-最近30天，近3个⽉-最近90天、近6个⽉-最近180天，近12个⽉-最近360天，夜间：凌晨1-6点。
            </div>
          </div>
          <div
            class=""
            v-if="Lendingintention == []"
            style="text-align: center; color: #393939"
          >
            <h3>无数据</h3>
          </div>
         
          <!-- <div v-else style="text-align: center">没有信息哦~</div> -->
          <div>
            <!-- <Table
            v-if="keyfou != [] && keytwo != []"
            ref="Arrays"
            :table="Arrays"
            @arrayMaps="getTable"
            :totalList="Arraytype"
            :keyfou="keyfou"
            :keytwo="keytwo"
          /> -->
            <Table
              v-if="keyfou != [] && keytwo != []"
              :table="dataList"
            ></Table>
            <div
              class=""
              v-if="Arrays == []"
              style="text-align: center; color: #393939"
            >
              <h3>无数据</h3>
            </div>
          </div>
        </div>
        <div class="Risk">
          <div class="head flex_c">
            <div class="red">—</div>
            <div class="text_C margin">风险识别 - 命中详情</div>
            <div class="red">—</div>
          </div>
          <div class="Riskcontent" v-if="Executor != []">
            <div class="flex_c">
              <div class="flex_b content">
                <h3 class="">法院被执行人 - 限高被执行人</h3>
                <div v-if="Executor != []">
                  <div
                    class="label"
                    v-if="
                      myMixintable.法院被执行人限高产品风险结果.verdict ==
                      '高风险'
                    "
                    style="background: #ffeaed; color: #ad001a"
                  >
                    高风险
                  </div>
                  <div
                    class="label"
                    v-else-if="
                      myMixintable.法院被执行人限高产品风险结果.verdict ==
                      '中风险'
                    "
                    style="background: #eefaec; color: #35ad00"
                  >
                    中风险
                  </div>
                  <div
                    class="label"
                    v-else-if="
                      myMixintable.法院被执行人限高产品风险结果.verdict ==
                      '低风险'
                    "
                    style="background: #ffe9d3; color: #ad0000"
                  >
                    低风险
                  </div>
                </div>
              </div>
            </div>
            <div v-if="Executor != []">
              <div
                class="form"
                style="margin-top: 15px"
                v-for="(item, index) in Executor"
                :key="index"
              >
                <div>
                  <header class="header">
                    <div>{{ item.casenum }}</div>
                  </header>
                  <div class="formRisk">
                    <div class="flex_b">
                      <div style="margin: 10px 10px">姓名:{{ item.iname }}</div>
                      <div style="margin: 10px 10px">
                        性别:{{ item.sexname }}
                      </div>
                      <div style="margin: 10px 10px">年龄:{{ item.age }}</div>
                    </div>
                    <form action="">
                      <div class="formName">
                        <label for="name">执行法院</label>
                        <span class="text" style="color: #ad001a">{{
                          item.courtname
                        }}</span>
                      </div>
                      <div class="formName">
                        <label for="name"> 地域名称 </label>
                        <span class="text" style="color: #ad001a">{{
                          item.areaname
                        }}</span>
                      </div>
                      <div class="formName">
                        <label for="name">立案时间</label>
                        <span class="text" style="color: #ad001a">{{
                          item.publishdate
                        }}</span>
                      </div>
                      <div class="formName">
                        <label for="name">发布时间</label>
                        <span class="text" style="color: #ad001a">{{
                          item.regdate
                        }}</span>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            <div
              class=""
              v-if="Executor.length == 0"
              style="text-align: center; color: #393939"
            >
              <h3>无数据</h3>
            </div>
          </div>

          <div class="Riskcontent">
            <div class="flex_c">
              <div class="flex_b content">
                <h3 class="overflow">法院裁判文书</h3>
                <div v-if="myMixintable.法院判决文书产品风险结果 != {}">
                  <div
                    class="label"
                    v-if="
                      myMixintable.法院判决文书产品风险结果.verdict == '高风险'
                    "
                    style="background: #ffeaed; color: #ad001a"
                  >
                    高风险
                  </div>
                  <div
                    class="label"
                    v-else-if="
                      myMixintable.法院判决文书产品风险结果.verdict == '中风险'
                    "
                    style="background: #ffe9d3; color: #ad0000"
                  >
                    中风险
                  </div>
                  <div
                    class="label"
                    v-else-if="
                      myMixintable.法院判决文书产品风险结果.verdict == '低风险'
                    "
                    style="background: #eefaec; color: #35ad00"
                  >
                    低风险
                  </div>
                </div>
              </div>
            </div>
            <div
              class=""
              v-if="myMixintable.法院判决文书产品风险结果 == {}"
              style="text-align: center; color: #393939"
            >
              <h3>无数据</h3>
            </div>
            <div class="document" v-if="information != []">
              <div class="form">
                <div
                  class="formRisk"
                  v-for="(item, index) in information"
                  :key="index"
                >
                  <div action="">
                    <div class="formName">
                      <label for="name">法院名称</label>
                      <span class="text" style="color: #ad001a">{{
                        item.court
                      }}</span>
                    </div>
                    <div class="formName">
                      <label for="name"> 法院等级</label>
                      <span class="text" style="color: #ad001a">{{
                        item.courtRank
                      }}</span>
                    </div>
                    <div class="formName">
                      <label for="name"
                        >案&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;由</label
                      >
                      <span class="text" style="color: #ad001a">{{
                        item.caseCause
                      }}</span>
                    </div>
                    <div class="formName">
                      <label for="name">五级分类</label>
                      <span class="text" style="color: #ad001a">{{item.eventLevel =="-1"?"负向":item.eventLevel =="0"?"中性":item.eventLevel =="1"?"正好":item.eventLevel =="2"?"利好":""}}</span>
                    </div>
                    <div class="formName flex_b">
                      <div>
                        <label for="name">匹&nbsp;&nbsp;配&nbsp;&nbsp;度</label>
                        <span class="text" style="color: #ad001a">{{
                          item.matchRatio
                        }}</span>
                      </div>
                      <button class="ckbt" @click="cankan(item)">
                        查看详情
                      </button>
                    </div>
                  </div>
                  <div v-if="item.status == 1">
                    <div style="margin: 10px 0">
                      <div
                        class="header flex_b"
                        style="font-size: 12px; border-radius: 0"
                      >
                        <div>当事人名称</div>
                        <div>当事人类型</div>
                        <div>当事人立场</div>
                        <div>判决金额</div>
                        <div>结果</div>
                      </div>
                      <div
                        class="table"
                        v-for="(items, index) in item.prResult"
                        :key="index"
                      >
                        <div class="flex_b" style="width: 91%; margin: 0 auto">
                          <div style="width: 60px;position: relative; left: 10px">{{ items.pname }}</div>
                          <div style="position: relative; left: -5px">
                            {{ items.partyType }}
                          </div>
                          <div style="position: relative; left: -5px">
                            {{ items.partyTitle }}
                          </div>
                          <div style="position: relative;  left: -5px" >
                            {{ items.partyFee }}元
                          </div>
                          <div style="position: relative; left: -8px">
                            {{ items.partyResult }}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div style="width: 95%; margin: 0 auto">
                      <div class="Casenumber">案号 ：{{ item.caseNo }}</div>
                      <div class="Trial"></div>
                      <div class="flexs">
                        <div>审结时间</div>
                        <div style="margin: 0 10px">2018年11⽉08⽇</div>
                      </div>
                      <div class="flexs">
                        <div>文书类型</div>
                        <div style="margin: 0 10px">{{ item.dataType }}</div>
                      </div>
                      <div class="flexs">
                        <div>
                          内&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;容
                        </div>
                        <div style="margin: 0 10px; width: 200px">
                          {{ item.judgeResult }}
                        </div>
                      </div>
                      <div class="flexs">
                        <div>审结时间</div>
                        <div style="margin: 0 10px; width: 250px">
                          {{ item.foundation }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="" v-else style="text-align: center; color: #393939">
              <h3>无数据</h3>
            </div>
          </div>

          <div
            v-if="information != []"
            style="width: 90%; margin: 0 auto; color: #a3a1a1"
            v-html="text"
          ></div>
        </div>

        <!-- <div v-else style="text-align: center">没有信息哦~</div> -->
      </div>
      <div class="Risk">
        <div class="Riskcontent">
          <div class="flex_c">
            <div class="flex_b content">
              <h3 class="overflow">法院信息详情</h3>
              <div v-if="myMixintable.法院信息产品风险结果 != {}">
                <div
                  class="label"
                  v-if="myMixintable.法院信息产品风险结果.verdict == '高风险'"
                  style="background: #ffeaed; color: #ad001a"
                >
                  高风险
                </div>
                <div
                  class="label"
                  v-else-if="
                    myMixintable.法院信息产品风险结果.verdict == '中风险'
                  "
                  style="background: #eefaec; color: #35ad00"
                >
                  中风险
                </div>
                <div
                  class="label"
                  v-else-if="
                    myMixintable.法院信息产品风险结果.verdict == '低风险'
                  "
                  style="background: #ffe9d3; color: #ad0000"
                >
                  低风险
                </div>
              </div>
            </div>
          </div>
          <div
            class=""
            v-if="Dishonestperson == {}"
            style="text-align: center; color: #393939"
          >
            <h3>无数据</h3>
          </div>
          <div v-if="Dishonestperson != []">
            <div
              class="form"
              v-for="(item, index) in Dishonestperson"
              :key="index"
            >
              <header class="header flex_b">
                <div>{{item.dataType == "shixin"?"失信被执⾏⼈":item.dataType == "zxgg"?"执行公告":item.dataType == "cpws"?"裁判文书":item.dataType == "fygg"?"法院公告":item.dataType == "bgt"?"曝光台":item.dataType == "ktgg"?"开庭公告":item.dataType == "ajlc"?"案件流程":item.dataType == "wdhmd"?"网贷黑名单":""}}</div>
                <div>匹配度 1</div>
              </header>
              <div class="formRisk" v-show="item.dataType == 'shixin'">
                <div class="flex_b">
                  <div>
                    <div style="margin: 10px 10px">
                      案&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;号:{{
                        item.caseNo
                      }}
                    </div>
                    <div style="margin: 10px 10px">
                      法院名称:{{ item.court }}
                    </div>
                  </div>
                  <button
                    class="ckbt"
                    style="margin: 0 10px"
                    @click="cankans(item)"
                  >
                    查看详情
                  </button>
                </div>
                <form action="" v-if="item.status == 1">
                  <div class="flex_b formName">
                    <label for="name">⽴案时间</label>
                    <div class="" style="color: #ad001a">
                      {{ item.sortTimeString }}
                    </div>
                  </div>
                  <div class="flex_b formName">
                    <label for="name"> 被执⾏⼈姓名 </label>
                    <div style="color: #ad001a">{{ item.pname }}</div>
                  </div>
                  <div class="flex_b formName">
                    <label for="name">⾝份证号/组织机构代码</label>
                    <div style="color: #ad001a">{{ item.idcardNo }}</div>
                  </div>
                  <div class="flex_b formName">
                    <label for="name">执⾏依据⽂号</label>
                    <div style="color: #ad001a">{{ item.execMoney }}</div>
                  </div>
                  <div class="flex_b formName">
                    <label for="name">做出执⾏依据单位</label>
                    <div style="color: #ad001a">{{ item.court }}</div>
                  </div>
                  <div class="flex_b formName">
                    <label for="name">被执⾏⼈的履⾏情况</label>
                    <div style="color: #ad001a">{{ item.caseCause }}</div>
                  </div>
                  <div class="flex_b formName">
                    <label for="name">内容概要</label>
                    <div style="color: #ad001a">{{ item.body }}</div>
                  </div>
                </form>
              </div>
              <div class="formRisk" v-show="item.dataType == 'zxgg'">
                <div class="flex_b">
                  <div>
                    <div style="margin: 10px 10px">
                      案&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;号:{{
                        item.caseNo
                      }}
                    </div>
                    <div style="margin: 10px 10px">
                      法院名称:{{ item.court }}
                    </div>
                  </div>
                  <button
                    class="ckbt"
                    style="margin: 0 10px"
                    @click="cankans(item)"
                  >
                    查看详情
                  </button>
                </div>
                <form action="" v-if="item.status == 1">
                 <div class="flex_b formName">
                    <label for="name"> 标题 </label>
                    <div style="color: #ad001a">{{ item.title }}</div>
                  </div>
                  <div class="flex_b formName">
                    <label for="name">⽴案时间</label>
                    <div class="" style="color: #ad001a">
                      {{ item.sortTimeString }}
                    </div>
                  </div>
                  <div class="flex_b formName">
                    <label for="name"> 被执⾏⼈姓名 </label>
                    <div style="color: #ad001a">{{ item.pname }}</div>
                  </div>
                  <div class="flex_b formName">
                    <label for="name">⾝份证号/组织机构代码</label>
                    <div style="color: #ad001a">{{ item.idcardNo }}</div>
                  </div>
                   <div class="flex_b formName">
                    <label for="name">案件状态</label>
                    <div style="color: #ad001a">{{ item.status }}</div>
                  </div>
                   <div class="flex_b formName">
                    <label for="name">执⾏标的</label>
                    <div style="color: #ad001a">{{ item.execMoney }}</div>
                  </div>
                  <div class="flex_b formName">
                    <label for="name">内容概要</label>
                    <div style="color: #ad001a">{{ item.body }}</div>
                  </div>
                </form>
              </div>
               <div class="formRisk" v-show="item.dataType == 'cpws'">
                <div class="flex_b">
                  <div>
                    <div style="margin: 10px 10px">
                      案&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;号:{{
                        item.caseNo
                      }}
                    </div>
                    <div style="margin: 10px 10px">
                      法院名称:{{ item.court }}
                    </div>
                  </div>
                  <button
                    class="ckbt"
                    style="margin: 0 10px"
                    @click="cankans(item)"
                  >
                    查看详情
                  </button>
                </div>
                <form action="" v-if="item.status == 1">
                 <div class="flex_b formName">
                    <label for="name"> 标题 </label>
                    <div style="color: #ad001a">{{ item.title }}</div>
                  </div>
                  <div class="flex_b formName">
                    <label for="name">审判时间 </label>
                    <div class="" style="color: #ad001a">
                      {{ item.sortTimeString }}
                    </div>
                  </div>
                  <div class="flex_b formName">
                    <label for="name"> ⽂书类型 </label>
                    <div style="color: #ad001a">{{ item.caseType }}</div>
                  </div>
                  <div class="flex_b formName">
                    <label for="name">⾝份证号/组织机构代码</label>
                    <div style="color: #ad001a">{{ item.idcardNo }}</div>
                  </div>
                  <div class="flex_b formName">
                    <label for="name">审理程序</label>
                    <div style="color: #ad001a">{{ item.trialProcedure }}</div>
                  </div>
                  <div class="flexb formName">
                    <label for="name">裁判结果</label>
                    <div style="color: #ad001a;width: 250px;" >{{ item.judgeResult }}</div>
                  </div>
                  <div class="flexb formName">
                    <label for="name">依据</label>
                    <div style="color: #ad001a;width: 270px;">{{ item.yiju }}</div>
                  </div>
                   <div class="flex_b formName">
                    <label for="name">案由</label>
                    <div style="color: #ad001a">{{ item.caseCause }}</div>
                  </div>
                  <div class="flex_b formName">
                    <label for="name">内容概要</label>
                    <div style="color: #ad001a">{{ item.body }}</div>
                  </div>
                </form>
              </div>
               <div class="formRisk" v-show="item.dataType == 'fygg'">
                <div class="flex_b">
                  <div>
                    <div style="margin: 10px 10px">
                      案&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;号:{{
                        item.caseNo
                      }}
                    </div>
                    <div style="margin: 10px 10px">
                      法院名称:{{ item.court }}
                    </div>
                  </div>
                  <button
                    class="ckbt"
                    style="margin: 0 10px"
                    @click="cankans(item)"
                  >
                    查看详情
                  </button>
                </div>
                <form action="" v-if="item.status == 1">
                  <div class="flex_b formName">
                    <label for="name">发布时间</label>
                    <div class="" style="color: #ad001a">
                      {{ item.sortTimeString }}
                    </div>
                  </div>
                  <div class="flex_b formName">
                    <label for="name"> 原告  </label>
                    <div style="color: #ad001a">{{ item.plaintiff }}</div>
                  </div>
                  <div class="flex_b formName">
                    <label for="name">被告</label>
                    <div style="color: #ad001a">{{ item.idcardNo }}</div>
                  </div>
                  <div class="flex_b formName">
                    <label for="name">版⾯</label>
                    <div style="color: #ad001a">{{ item.layout }}</div>
                  </div>
                  <div class="flex_b formName">
                    <label for="name">公告类型</label>
                    <div style="color: #ad001a">{{ item.court }}</div>
                  </div>
                  <div class="flex_b formName">
                    <label for="name">内容概要</label>
                    <div style="color: #ad001a">{{ item.body }}</div>
                  </div>
                </form>
              </div>
               <div class="formRisk" v-show="item.dataType == 'bgt'">
                <div class="flex_b">
                  <div>
                    <div style="margin: 10px 10px">
                      案&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;号:{{
                        item.caseNo
                      }}
                    </div>
                    <div style="margin: 10px 10px">
                      法院名称:{{ item.court }}
                    </div>
                  </div>
                  <button
                    class="ckbt"
                    style="margin: 0 10px"
                    @click="cankans(item)"
                  >
                    查看详情
                  </button>
                </div>
                <form action="" v-if="item.status == 1">
                  <div class="flex_b formName">
                    <label for="name">⽴案时间</label>
                    <div class="" style="color: #ad001a">
                      {{ item.sortTimeString }}
                    </div>
                  </div>
                  <div class="flex_b formName">
                    <label for="name"> 曝光⽇期 </label>
                    <div style="color: #ad001a">{{ item.bgDate }}</div>
                  </div>
                   <div class="flex_b formName">
                    <label for="name">申请⼈</label>
                    <div style="color: #ad001a">{{ item.proposer }}</div>
                  </div>
                  <div class="flex_b formName">
                    <label for="name">⾝份证号/组织机构代码</label>
                    <div style="color: #ad001a">{{ item.idcardNo }}</div>
                  </div>
                  <div class="flex_b formName">
                    <label for="name">未执⾏⾦额 </label>
                    <div style="color: #ad001a">{{ item.unnexeMoney }}</div>
                  </div>
                  <div class="flex_b formName">
                    <label for="name">标的⾦额</label>
                    <div style="color: #ad001a">{{ item.execMoney }}</div>
                  </div>
                  <div class="flex_b formName">
                    <label for="name">依据 </label>
                    <div style="color: #ad001a">{{ item.yiju }}</div>
                  </div>
                    <div class="flex_b formName">
                    <label for="name">地址 </label>
                    <div style="color: #ad001a">{{ item.address }}</div>
                  </div>
                  <div class="flex_b formName">
                    <label for="name">内容概要</label>
                    <div style="color: #ad001a">{{ item.body }}</div>
                  </div>
                </form>
              </div>
               <div class="formRisk" v-show="item.dataType == 'ktgg'">
                <div class="flex_b">
                  <div>
                    <div style="margin: 10px 10px">
                      案&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;号:{{
                        item.caseNo
                      }}
                    </div>
                    <div style="margin: 10px 10px">
                      法院名称:{{ item.court }}
                    </div>
                  </div>
                  <button
                    class="ckbt"
                    style="margin: 0 10px"
                    @click="cankans(item)"
                  >
                    查看详情
                  </button>
                </div>
                <form action="" v-if="item.status == 1">
                <div class="flex_b formName">
                    <label for="name"> 标题 </label>
                    <div style="color: #ad001a">{{ item.title }}</div>
                  </div>
                  <div class="flex_b formName">
                    <label for="name">开庭时间</label>
                    <div class="" style="color: #ad001a">
                      {{ item.sortTimeString }}
                    </div>
                  </div>
                  <div class="flex_b formName">
                    <label for="name"> 原告  </label>
                    <div style="color: #ad001a">{{ item.plaintiff }}</div>
                  </div>
                  <div class="flex_b formName">
                    <label for="name">被告 </label>
                    <div style="color: #ad001a">{{ item.defendant}}</div>
                  </div>
                  <div class="flex_b formName">
                    <label for="name">案由 </label>
                    <div style="color: #ad001a">{{ item.caseCause }}</div>
                  </div>
                  <div class="flex_b formName">
                    <label for="name">内容概要</label>
                    <div style="color: #ad001a">{{ item.body }}</div>
                  </div>
                </form>
              </div>
               <div class="formRisk" v-show="item.dataType == 'ajlc'">
                <div class="flex_b">
                  <div>
                    <div style="margin: 10px 10px">
                      案&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;号:{{
                        item.caseNo
                      }}
                    </div>
                    <div style="margin: 10px 10px">
                      法院名称:{{ item.court }}
                    </div>
                  </div>
                  <button
                    class="ckbt"
                    style="margin: 0 10px"
                    @click="cankans(item)"
                  >
                    查看详情
                  </button>
                </div>
                <form action="" v-if="item.status == 1">
                  <div class="flex_b formName">
                    <label for="name">⽴案时间</label>
                    <div class="" style="color: #ad001a">
                      {{ item.sortTimeString }}
                    </div>
                  </div>
                    <div class="flex_b formName">
                    <label for="name"> 原告 </label>
                    <div style="color: #ad001a">{{ item.plaintiff }}</div>
                  </div>
                    <div class="flex_b formName">
                    <label for="name"> 被告 </label>
                    <div style="color: #ad001a">{{ item.defendant }}</div>
                  </div>
                    <div class="flex_b formName">
                    <label for="name"> 案件类别 </label>
                    <div style="color: #ad001a">{{ item.caseType }}</div>
                  </div>
                    <div class="flex_b formName">
                    <label for="name"> 审理程序 </label>
                    <div style="color: #ad001a">{{ item.trialProcedure }}</div>
                  </div>
                    <div class="flex_b formName">
                    <label for="name"> 执⾏状态 </label>
                    <div style="color: #ad001a">{{ item.status }}</div>
                  </div>
                  <div class="flex_b formName">
                    <label for="name"> 案件状态 </label>
                    <div style="color: #ad001a">{{ item.caseStatus }}</div>
                  </div>
                  <div class="flex_b formName">
                    <label for="name">诉讼标的</label>
                    <div style="color: #ad001a">{{ item.actionObject }}</div>
                  </div>
                  <div class="flex_b formName">
                    <label for="name">有效⽇期</label>
                    <div style="color: #ad001a">{{ item.sortTime }}</div>
                  </div>
                  <div class="flex_b formName">
                    <label for="name">案由</label>
                    <div style="color: #ad001a">{{ item.caseCause }}</div>
                  </div>
                  <div class="flex_b formName">
                    <label for="name">内容概要</label>
                    <div style="color: #ad001a">{{ item.body }}</div>
                  </div>
                </form>
              </div>
                <div class="formRisk" v-show="item.dataType == 'wdhmd'">
                <div class="flex_b">
                  <div>
                    <div style="margin: 10px 10px">
                      案&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;号:{{
                        item.caseNo
                      }}
                    </div>
                    <div style="margin: 10px 10px">
                      法院名称:{{ item.court }}
                    </div>
                  </div>
                  <button
                    class="ckbt"
                    style="margin: 0 10px"
                    @click="cankans(item)"
                  >
                    查看详情
                  </button>
                </div>
                <form action="" v-if="item.status == 1">
                  <div class="flex_b formName">
                    <label for="name">贷款时间</label>
                    <div class="" style="color: #ad001a">
                      {{ item.sortTimeString }}
                    </div>
                  </div>
                  <div class="flex_b formName">
                    <label for="name"> 姓名 </label>
                    <div style="color: #ad001a">{{ item.pname }}</div>
                  </div>
                  <div class="flex_b formName">
                    <label for="name">⾝份证号/组织机构代码</label>
                    <div style="color: #ad001a">{{ item.idcardNo }}</div>
                  </div>
                  <div class="flex_b formName">
                    <label for="name">企业注册号</label>
                    <div style="color: #ad001a">{{ item.execMoney }}</div>
                  </div>
                  <div class="flex_b formName">
                    <label for="name">来源单位名称</label>
                    <div style="color: #ad001a">{{ item.sourceName }}</div>
                  </div>
                  <div class="flex_b formName">
                    <label for="name">未还/罚息</label>
                    <div style="color: #ad001a">{{ item.whfx }}</div>
                  </div>
                  <div class="flex_b formName">
                    <label for="name">本⾦/本息</label>
                    <div style="color: #ad001a">{{ item.bjbx }}</div>
                  </div>
                  <div class="flex_b formName">
                    <label for="name">已还⾦额</label>
                    <div style="color: #ad001a">{{ item.yhje }}</div>
                  </div>
                  <div class="flex_b formName">
                    <label for="name">⼿机号</label>
                    <div style="color: #ad001a">{{ item.mobile }}</div>
                  </div>
                  <div class="flex_b formName">
                    <label for="name">信息更新时间</label>
                    <div style="color: #ad001a">{{ item.updateTime }}</div>
                  </div>
                  <div class="flex_b formName">
                    <label for="name">居住地址</label>
                    <div style="color: #ad001a">{{ item.address }}</div>
                  </div>
                  <div class="flex_b formName">
                    <label for="name">内容概要</label>
                    <div style="color: #ad001a">{{ item.body }}</div>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div class="" v-else style="text-align: center; color: #393939">
            <h3>无数据</h3>
          </div>
          <span class="gray" style="margin: 0 4%" v-if="Dishonestperson != []"
            >注：匹配度取值为0-1，值越⼤，表⽰匹配程度越⾼。</span
          >
        </div>
      </div>

      <div class="Risk">
        <div class="Riskcontent">
          <div>
            <div class="flex_c">
              <div class="flex_b content">
                <h3 class="">特殊名单</h3>
                <div
                  v-if="
                    myMixintable.特殊名单产品风险结果 != {} &&
                    myMixintable.特殊名单产品风险结果 != undefined
                  "
                >
                  <div>
                    <div
                      class="label"
                      v-if="
                        myMixintable.特殊名单产品风险结果.verdict ==
                        '高风险'
                      "
                      style="background: #ffeaed; color: #ad001a"
                    >
                      高风险
                    </div>
                    <div
                      class="label"
                      v-else-if="
                        myMixintable.特殊名单产品风险结果.verdict ==
                        '中风险'
                      "
                      style="background: #ffe9d3; color: #ad0000"
                    >
                      中风险
                    </div>
                    <div
                      class="label"
                      v-else-if="
                        myMixintable.特殊名单产品风险结果.verdict ==
                        '低风险'
                      "
                      style="background: #eefaec; color: #35ad00"
                    >
                      低风险
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="form" v-if="order != []">
              <header class="header flex_b">
                <div>序号</div>
                <div>命中</div>
                <div style="position: relative; right: 10px">结果</div>
              </header>
              <div
                class="flex_b orders"
                style="margin: 0 auto"
                v-for="(item, index) in order"
                :Key="index"
              >
                <div style="flex: 1.5; position: relative; left: 7%">
                  {{ index }}
                </div>
                <div style="flex: 2; position: relative; right: 10px">
                  {{ item.name }}
                </div>
                <div style="flex: 1; color: #ad0000; text-align: center">
                  {{ item.value }}
                </div>
              </div>
              <div class="gray" style="width: 90%; margin: 0 auto">
                1.
                ⼀度关系：我司关系库中与本⼈有直接关联的⼈，⼀般是直系亲属、关系紧密的朋友；⼆度关系：我司关系库中与⼀度关系有直接关系的⼈。
                2.
                中风险，指90天以上逾期；⼀般风险，指30/60/90天逾期；资信不佳，指申请资料涉嫌伪冒；拒绝，指判定不良被拒绝；⾼⻛险，指曾⽆法联系；⾼危⾏为，指关联⼿机号>3或⼿机号关联⾝份证>3。
              </div>
            </div>
            <div class="" v-else style="text-align: center; color: #393939">
              <h3>无数据</h3>
            </div>
          </div>
        </div>
      </div>
    </div>
    <footer>
      <div style="width: 90%; margin: 0 auto">
        <div class="footers">
          1.客⼾使⽤本报告，需经过被查询⼈授权，客⼾承担因授权不充分引起的任何法律责任。<br />
          2.本报告仅限客⼾内部使⽤，请妥善保管本报告，不得向任何第三⽅泄露或允许任何第三⽅使⽤本报告。<br />
          3.本报告仅供客⼾参考，不作为客⼾决策的依据。<br />
          4.未经我司书⾯许可，任何⼈不得擅⾃复制、摘录、编辑、转载、披露和发表。<br />
          5.请确保在安全的物理及⽹络环境操作并确保导出内容的保密、安全以及<br />
        </div>
      </div>
    </footer>
  </div>
</template>
<script>
import agree from "../assets/icons/agree.png";
import NOreconsideration from "../assets/icons/NOreconsideration.png";
import reconsideration from "../assets/icons/reconsideration.png";
import remove from "../assets/icons/remove.png";
import Treeview from "../components/Treeview.vue";
import Table from "../components/form.vue";
import { myMixins } from "../components/mixins";
import Clipboard from 'clipboard.js-master'
import { Toast } from "vant";
import { Base64 } from "js-base64"
export default {
  created() {
    // console.log("方法", this.$refs.Arrays);
    // this.$refs.Arrays.dataSuju()
  },
  mixins: [myMixins],
  components: { Treeview, Table },
  watch: {
    statusicon(newstatus, oldstatus) {
      newstatus == "通过"
        ? (this.Image = agree)
        : newstatus == "无建议"
        ? (this.Image = NOreconsideration)
        : newstatus == "复议"
        ? (this.Image = reconsideration)
        : newstatus == "拒绝"
        ? (this.Image = remove)
        : "";
    },
  },
 data(){
  return{
  textUrl:""
  }
 },
 methods:{
   lianjie() {
    console.log(this.buyPhone)
      let phone = this.buyPhone
      phone = Base64.encode(JSON.stringify(phone))
      let httpUrl = "https://pay.fkykj.cn/report?form=" + phone
      this.textUrl = httpUrl
      var clipboard = new Clipboard(".lianjie");
      clipboard.on("success", (e) => {
        Toast("复制成功")
        e.clearSelection();
        //释放内存，以防重复复制
        clipboard.destroy();
      });
      clipboard.on("error", () => {
        Toast("复制失败")
        clipboard.destroy();
      });
    },
 }
};
</script>

<style lang="less">
.body {
  width: 21%;
  margin: 0 auto;
  background: #f9f9f9;

  .lianjie {
    padding: 10px;
    z-index: 99;
    font-size: 15px;
    position: fixed;
    bottom: 30px;
    color: #ffffff;
    right: 20px;
    border: 0;
    border-radius: 7%;
    background: #ad001a;
  }
  .flexs {
    display: flex;
    margin: 10px 0;
  }
  .Tips {
    width: 345px;
    height: 48px;
    background: #f9f9f9;
    border: 1px solid #efecec;
    opacity: 1;
    border-radius: 6px;
  }
  .flexs_b {
    display: flex;
    justify-content: space-between;
  }
  .ckbt {
    width: 72px;
    height: 24px;
    background: #ad001a;
    color: #ffffff;
    line-height: 1;
    border: 1px solid #9e1f23;
    opacity: 1;
    border-radius: 4px;
  }
  .gray {
    font-size: 12px;
    font-family: PingFang SC;
    font-weight: 400;
    line-height: 19px;
    color: #a3a1a1;
  }
  .label {
    width: 48px;
    height: 22px;
    text-align: center;
    line-height: 2;
    opacity: 1;
    border-radius: 4px;
  }
  .header {
    padding: 15px;

    background: #c41e31;
    border-radius: 0px;
    .text {
      font-size: 16px;
      font-family: PingFang SC;
      font-weight: 600;
      color: #ffffff;
      opacity: 1;
    }
    .font {
      font-size: 40px;
      font-family: PingFang SC;
      font-weight: 600;
      color: #ffffff;
      opacity: 1;
    }
    .icon {
      width: 129px;
      height: 128px;
    }
    .wenzi {
      width: 302px;
      height: 16px;
      position: relative;
      top: -8px;
      font-size: 11px;
      font-family: PingFang SC;
      font-weight: 400;
      line-height: 0px;
      color: #ffffff;
      opacity: 1;
      .color{
        color: #fac430;
      }
      .while{
        color:#ffffff
      }
    }
  }
  .Contents {
    width: 95%;
    margin: 0 auto;
    text-align: left;
    font-size: 12px;
    font-family: PingFang SC;
    font-weight: 600;
    .information {
      margin: 0 auto;
      position: relative;
      bottom: 15px;
      // width: 50vh;
      padding: 10px 0;
      background: #ffffff;
      opacity: 1;
      border-radius: 4px;

      .form {
        margin: 0 auto;
        padding: 10px;
        .boxs {
          margin: 5px 0;
        }
        .title {
          width: 56px;
          height: 124px;
          margin: 0 5px;
          font-size: 15px;
          font-family: PingFang SC;
          font-weight: 600;
          color: #444444;
          opacity: 1;
        }
      }
    }
    .formbox {
      padding: 10px 0;
      margin: 15px auto;
      background: #ffffff;
      opacity: 1;
      border-radius: 4px;
      .text {
        width: 100%;
        font-size: 11px;
        font-family: PingFang SC;
        font-weight: 400;
        text-align: center;
        // line-height: 26px;
        color: #a3a1a1;
        opacity: 1;
      }
      .title {
        margin: 10px 10px;
      }
      .head {
        padding: 10px 0;
        font-size: 15px;
        font-weight: 600;
        background: #f5f5f5;
      }
      .content {
        margin: 0 10px;
        font-size: 12px;
        font-family: PingFang SC;
        font-weight: 600;
        padding: 5px 0;
        border-bottom: 1px solid #f5f5f5;
        opacity: 1;
      }
    }
    .Risk {
      // width: 53vh;
      padding: 10px 0;
      margin: 15px auto;
      background: #ffffff;
      opacity: 1;
      border-radius: 4px;
      .content {
        width: 95%;
      }
     
      .form {
        margin: 0 auto;
        background: #ffffff;
        opacity: 1;
        transition: 0.5s all;
        .formRisk {
          width: 95%;
          border-radius: 5px;
          margin: 10px auto;
          // height: 163px;
          background: #f9f9f9;
        }
        .formName {
          width: 95%;
          margin: 0 auto;
          font-size: 12px;
          font-family: PingFang SC;
          font-weight: 400;
          line-height: 24px;
          opacity: 1;

          .text {
            margin: 0 20px;
          }
        }
        .orders {
          width: 93.7%;
          padding: 5px 0;
          border: 1px solid #efecec;
        }
      }
      .header {
        width: 100%;
        margin: 0 auto;
        font-size: 14px;
        font-family: PingFang SC;
        font-weight: 400;
        line-height: 0px;
        color: #ffffff;
        background: #ad001a;
        border-radius: 6px 6px 0px 0px;
      }
      .text {
        width: 100%;
        font-size: 11px;
        font-family: PingFang SC;
        font-weight: 400;
        text-align: center;
        // line-height: 26px;
        color: #a3a1a1;
        opacity: 1;
      }
      .title {
        margin: 10px 25px;
      }
      .head {
        padding: 10px 0;
        font-size: 15px;
        font-weight: 600;
        background: #f5f5f5;
      }
      .content {
        margin: 0 10px;
        font-size: 12px;
        font-family: PingFang SC;
        font-weight: 600;
        padding: 5px 0;
        // border-bottom: 1px solid #f5f5f5;
        opacity: 1;
        .label {
          width: 48px;
          height: 22px;
          text-align: center;
          line-height: 2;
          opacity: 1;
          border-radius: 4px;
        }
      }
    }
    .table {
      width: 95%;
      margin: 0 auto;
      border: 1px solid #f7f7f7;
      background: #ffffff;
    }
    .Casenumber {
      padding: 5px;
      //  width: 93%;
      margin: 5px auto;
      // height: 28px;
      background: #ffeaed;
      opacity: 1;
      border-radius: 0px;
    }
    .Trial {
      // width: 93%;
      margin: 0px auto;
    }
    .assessment {
      width: 90%;
      margin: 10px auto;
      background: #ffffff;
      border: 1px solid #efecec;
      opacity: 1;
      border-radius: 6px;
      .box1 {
        width: 110px;
        height: 88px;
        background: linear-gradient(33deg, #ad001a 0%, #eb5358 100%);
        opacity: 1;
        text-align: center;
        border-radius: 6px 0px 0px 6px;
        .titles {
          position: relative;
          top: 10px;
          font-size: 14px;
          color: #ffffff;
        }
        .zhi {
          margin-top: 30px;
          font-size: 36px;
          font-family: PingFang SC;
          font-weight: 600;
          line-height: 0px;
          color: #ffffff;
        }
      }
    }
  }
  .footers {
    font-size: 12px;
    font-family: PingFang SC;
    font-weight: 400;
    line-height: 24px;
    color: #444444;
    opacity: 1;
  }
}
@media screen and (max-width: 600px) {
  .body {
    width: 100%;
    margin: 0 auto;
  }
}
</style>