<template>
  <div class="body flex_v">
   <div  class="icon font flex">
    <div style="width:150px">注册成功</div><img src="../assets/icons/xuanze.png" alt="" width="30" />
    <!-- <img src="../assets/icons/20220120154826.jpg" alt="" width="55" >
    <div class="font">风控眼</div> -->
   </div>
      <div class="flex">
        <img src="../assets/icons/dianji@2x.png" alt="" />
        <div class="border_top">
          <a href="#" style="color: #ad001a" @click="goTob">点击下载APP登录</a>
        </div>
      </div>
  </div>
</template>

<script>
export default {
   methods:{
     goTob() {
      var u = navigator.userAgent;
      var isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1; //android终端
      var isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);  //ios终端
      if(isAndroid) {
          window.location.href = "http://app.fkykj.cn/zqlr"
      } else if(isiOS) {
          window.location.href = "https://testflight.apple.com/join/PA9xJauG"
      } 
    },
   }
}
</script>

<style>
    .body{
     width: 100%;
     height: 600px;
     /* background: #f9f9f9; */
    }
    .font{
     font-size: 20px;
     font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
     font-weight: 500;
    }
    .icon{
     position: relative;
     bottom: 50px;
    }
</style>