<template>
  <div>
    <!-- <form id="_alipaysubmit_" name="alipaysubmit" action="https://openapi.alipaydev.com/gateway.do?app_id=2016092900623048&charset=UTF-8&format=json&method=alipay.trade.page.pay&notify_url=http%3A%2F%2F47.108.211.43%3A8001%2Fapp%2Fpay%2FzfbNotify&sign=myhtETqm64AwAC5QtLJRG%2BlN5O2BTAnlpLVKD2JfqRnLgAB4I%2BXcWFK5c0SN9L21OhTgCjYt5ykZkNMxGEcGghG0ZseWUQ8LjKOod%2BZJf5Ci6t5TklKD55XJBivqHJGYYUIOUThUBFSkC5KKe6rDDmvJugpFAOlsKXqZpImDnSRVivn0ZZaEhoi8BMzM3tHR5QaTyeRQYssUQLcSUbFLtZeYJjey%2Bl5YnJs3ryEhikHnEO2eWM8ohbvT1417vpLPQSHxPWEEvJcMagQpL%2FrUZQmqmCqgjX%2FBppQBi6Qk1EpfgPCv1Rsowyq1ihTQM0lnvi58jgd5V5aXPfF8RBHjGw%3D%3D&sign_type=RSA2&timestamp=2022-04-02+17%3A22%3A52&version=1.0" method="post"><input type="hidden" name="biz_content" value='{"body":"测试商品","out_trade_no":"6e77c55054694eb6ba3d0291a791d2cb","passback_params":"{\"userId\":\"1505714106701029400\",\"buyName\":\"赖霄\",\"buyPhone\":\"18981173779\",\"buyCard\":\"510722200006096640\",\"id\":\"1496769799982776300\",\"productId\":\"1496767590071054337\",\"productName\":\"测试商品\",\"userName\":\"素描\",\"userPhone\":\"13627609217\",\"oldCost\":70.0,\"newCost\":120.0,\"riseCost\":0.0,\"incCost\":50.0,\"glanceCount\":null}","product_code":"FAST_INSTANT_TRADE_PAY","subject":"测试商品","total_amount":"120.00"}'/></form> -->
    <from
      v-html="content"
      @submit="aliPay"
      id="_alipaysubmit_"
      name="alipaysubmit"
    ></from>
  </div>
</template>

<script>
export default {
  created() {
    let content = this.$route.query.htmlData;
    console.log(content)
    if (this.$route.query.status == 2) {
      location.href = content;
    } else {
      let text = content.indexOf("<script>");
      let str1 = content.substring(0, text);
      this.content = str1;
      setTimeout(() => {
        document.forms["_alipaysubmit_"].submit();
      }, 1000);
    }
  },

  data() {
    return {
      content: "",
      // status:tradeStatus.TRADE_SUCCESS,
    };
  },
  watch: {},
  mounted(){
      this.query()
  },
  methods: {
    query(){
      this.$http.post("https://openapi.alipay.com/gateway.do?timestamp=2013-01-01 08:08:08&method=alipay.trade.close&app_id=20110&sign_type=RSA2&sign=ERITJKEIJKJHKKKKKKKHJEREEEEEEEEEEE&version=1.0&charset=GBK&biz_content=AlipayTradeFastpayCloseModel").then(res=>{
        console.log(res)
      })
    }
  },
};
</script>

<style>
</style>