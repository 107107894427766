<template>
  <div class="about flex_c">
    <div class="Mantle flexe" v-show="Mantlebox">
      <div class="box">
        <img
          src="../assets/icons/jiantou2.png"
          alt=""
          width="80"
          class="img"
          style="z-index: 9; border: 0"
        />
        <div>亲,请打开浏览器查看哦~</div>
      </div>
    </div>
    <div class="flex_c form">
      <div action="">
        <div>
          <input
            type="text"
            class="input"
            name="buyName"
            placeholder="请输入您的姓名"
            v-model="formData.buyName"
          />
        </div>
        <div>
          <input
            type="text"
            class="input"
            name="buyPhone"
            placeholder="请输入手机号"
            @blur="blur"
            maxlength="11"
            v-model="formData.buyPhone"
          />
        </div>
        <div>
          <input
            type="text"
            class="input"
            name="buyCard"
            maxlength="18"
            @blur="blur"
            placeholder="请输入身份证号"
            v-model="formData.buyCard"
          />
        </div>
        <div class="flex_e" style="font-size: 15px; color: brown">
          <span @click="jump">历史记录</span>
        </div>
        <!-- <button class="bt"></button> -->
        <van-button class="bt" @click="text" block>打赏查询报告</van-button>
      </div>
    </div>
  </div>
</template>
<script>
import { Base64 } from "js-base64";
import { Toast } from "vant";
export default {
  data() {
    return {
      formData: {
        buyName: "", //买家
        buyPhone: "",
        buyCard: "",
        id: "",
        Mantlebox: false,
        productId: null,
        productName: "",
        newCost: null,
        userId: null,
      },
    };
  },
  beforeCreate() {
    var ua = window.navigator.userAgent.toLowerCase();
    if (ua.match(/MicroMessenger/i) == "micromessenger") {
      this.Mantlebox = true;
    } else {
      this.Mantlebox = false;
      console.log("不是微信浏览器");
    }
  },
  created() {
    let httpUrl = window.location.href;
    // let httpUrl = "https://pay.fkykj.cn/?form=ZXlKd2NtOWtkV04wU1dRaU9pSXhOVE14TVRrM016VXpOalF6TnpnMk1qUXhJaXdpY0hKdlpIVmpkRTVoYldVaU9pTERwTUs4d29IRHBNSzR3cHJEcWNLandvN0RxY0tad3FuRHFjS2l3b1REcE1LOHdyRERwc0tLd3FYRHBjS1J3b29pTENKcFpDSTZJakUxTXpZeE56UTJOREE1TmpVME1qY3lNREVpTENKdVpYZERiM04wSWpvd0xqQXpMQ0oxYzJWeVNXUWlPaUl4TlRFM016TTBNekl4TVRnNE5qTXdOVE13SW4wPQ%3D%3D"
    var str = httpUrl;
    var reg = new RegExp("%3D", "g");
    var a = str.replace(reg, "");
    httpUrl = a;
    httpUrl = httpUrl.split("=")[1] + "";
    let form = Base64.decode(httpUrl);
    form = Base64.decode(form);
    form = JSON.parse(form);
    console.log(form);
    let { newCost, productId, userId, productName, id } = form;
    this.read(form);
    this.formData.newCost = newCost;
    this.formData.productId = productId;
    this.formData.productName = productName;
    this.formData.userId = userId;
    this.formData.id = id;
  },
  methods: {
    read(form) {
      this.$http.post("/turnover/hincr", form).then((res) => {
        console.log("浏览成功");
      });
    },
    blur() {
      if (
        this.formData.buyPhone.length !== 11 &&
        this.formData.buyPhone !== ""
      ) {
        Toast("手机号位数11位");
      }
      if (this.formData.buyCard.length !== 18 && this.formData.buyCard !== "") {
        Toast("身份证号18位");
      }
    },
    text() {
      let { buyName, buyPhone, buyCard } = this.formData;
      if (buyName == "") {
        Toast("姓名不能为空");
      } else if (buyPhone == "") {
        Toast("手机不能为空");
      } else if (buyCard == "") {
        Toast("身份证不能为空");
      } else {
        this.goquery();
      }
    },
    jump() {
      this.$router.push({ path: "/History" });
    },
    goquery() {
      this.$router.push({
        path: "/Payment",
        query: { form: JSON.stringify(this.formData) },
      });
    },
  },
};
</script>
<style lang="less">
.bt {
  width: 300px;
  height: 40px;
  margin: 50px auto;
  background: #ad001a;
  opacity: 1;
  color: #fff;
  font-size: 15px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #ffffff;
  opacity: 1;
  border: none;
  border-radius: 4px;
}
.Mantle {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(rgb(5, 5, 5), 0.5);
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #ffffff;
  z-index: 9;
  .box {
    // width:190px;
    // height: 100px;
    margin: 40px 20px;
    z-index: 2;
    // border: 1px solid rgba(rgb(4, 4, 4), 0.001);
    background-color: rgba(rgb(4, 4, 4), 0.001);
    border: none;
    // background: pink;
  }
  .img {
    position: relative;
    left: 70px;
    //  background-color: rgba(rgb(5, 5, 5), 0.5);
  }
}
.about {
  width: 100%;
  height: 100%;
}
.form {
  margin: 40% 0;
}
.input {
  width: 300px;
  height: 50px;
  font-size: 15px;
  text-indent: 10px;
  margin: 10px auto;
  background: #e9e6e6;
  border: 1 solid #f5eded;
  border-radius: 4px;
  border: 0px;
  opacity: 1;
}
</style>
