import { setCookie, getCookie } from "../components/cooike"
import { Toast } from "vant";
import { Base64 } from "js-base64"
export const myMixins = {
  // components:{},
  data() {
    return {
      bodystatus: false,
      operation: {},
      newTime: "",//7天时间
      keyfou: [],
      keytwo: [],
      textUrl: "",
      buyPhone:"",
      age: 18,
      vatlur:"无",
      myMixintable: {},
      newstatus: 0,
      Image: "",
      statusicon: "",
      text: "1. 匹配度：取值0~1。值越⼤，表⽰匹配程度越⾼。2. 五级分类：警告、负向、中性、正向、利好。在司法数据中只有警告、负向、中性三级。其中警告表⽰较⾼⾏政单位作出的判决，或⾦额较⼤，或为刑事类案件等；负向表⽰⼀般案件，涉及⾦额较⼩，未造成严重后果；中性有可能没有⾦额判定",
      status: 2,
      title: [{ name: 1 }],
      details: [],//要素检查
      Operator: [],//运营商核验
      Executor: [],//执行人
      information: [],//法院判决文书详情
      Arrays: [],
      Arrayproduct: [],//本人在个产品
      Arraytype: [],//本人在各机构
      Arrayinstitution: [],//本人在本机构
      Arrayspecial: [],// 本人在特殊期间
      from: {
        value1: [],
        value2: [],
        value3: [],
        value4: [],
        value5: [],
        value6: [],
      },
      risk: [],
      Dishonestperson: [

      ],
      toloan: [],
      Lendingintention: [{ title: "借贷意向验证", status: 1 }],
      Speciallist: [
        {
          title: "特殊名单",
          status: 1,
        },
      ],
      order: [

      ],
      judgment: [

      ],
      Trialcourt: [

      ],

      list: [
        { freheight: 0, instheight: 0 },
        { freheight: 0, instheight: 0 },
        { freheight: 0, instheight: 0 },
        { freheight: 0, instheight: 0 },
        { freheight: 0, instheight: 0 },
      ],
      dataList: [],
      product: [],
    }
  },

  mounted() {
    let d7 = 'd7'
    this.getTable(d7)
  },
  watch: {
    newTime(news, ord) {
      let dayTime = new Date()
      dayTime = dayTime.getTime();
      if (dayTime < news) {
        this.bodystatus = true
      } else {
        this.bodystatus = false
        Toast({ message: "链接失效，有效时间为7天", duration: 2000 })
        uni.switchTab({
          url: '/pages/zxpublic/nav/order'
        });

      }
    
    }
  },
  methods: {
    slices(Array, number) {
      // console.log(Array)
      if (Array == undefined) {
        return false
      } else {
        return Array.slice(0, number)
      }

    },
    dataTime(dateTime) {
      let zs = ""
      var date = new Date(dateTime);
      var time1 = date.getTime();
      let days = 86400000 * 7
      zs = days + time1
      this.newTime = new Date(zs);
    },
    cankan(item) {
      for (let i = 0; i < this.information.length; i++) {
        if (this.information[i] == item) {
          if (this.information[i].status == 0) {
            this.$set(this.information[i], "status", 1);
            this.$forceUpdate()
          } else {
            this.$set(this.information[i], "status", 0);
            this.$forceUpdate()
          }
        }
      }
    },
    cankans(item) {
      for (let i = 0; i < this.Dishonestperson.length; i++) {
        if (this.Dishonestperson[i] == item) {
          if (this.Dishonestperson[i].status == 0) {
            this.$set(this.Dishonestperson[i], "status", 1);
            this.$forceUpdate()
          } else {
            this.$set(this.Dishonestperson[i], "status", 0);
            this.$forceUpdate()
          }
        }
      }
    },
    Treeviews(array) { //树状图
      let d7 = 0
      let d15 = 0
      let m1 = 0
      let m3 = 0
      let m6 = 0
      let m12 = 0
      let d7c = 0
      let d15c = 0
      let m1c = 0
      let m3c = 0
      let m6c = 0
      let m12c = 0
      let Araay = array[0]
      d7c = Araay[0].d7的id的bank_allnum + Araay[1].d7的id的nbank_allnum + Araay[2].d7的cell的bank_allnum + Araay[3].d7的cell的nbank_allnum
      d7 = Araay[0].d7的id的bank_orgnum + Araay[1].d7的id的nbank_orgnum + Araay[2].d7的cell的bank_orgnum + Araay[3].d7的cell的nbank_orgnum
      d15c = Araay[4].d15的id的bank_allnum + Araay[5].d15的id的nbank_allnum + Araay[6].d15的cell的bank_allnum + Araay[7].d15的cell的nbank_allnum
      d15 = Araay[4].d15的id的bank_orgnum + Araay[5].d15的id的nbank_orgnum + Araay[6].d15的cell的bank_orgnum + Araay[7].d15的cell的nbank_orgnum
      m1c = Araay[8].m1的id的bank_allnum + Araay[9].m1的id的nbank_allnum + Araay[10].m1的cell的bank_allnum + Araay[11].m1的cell的nbank_allnum
      m1 = Araay[8].m1的id的bank_orgnum + Araay[9].m1的id的nbank_orgnum + Araay[10].m1的cell的bank_orgnum + Araay[11].m1的cell的nbank_orgnum
      m3c = Araay[12].m3的id的bank_allnum + Araay[13].m3的id的nbank_allnum + Araay[14].m3的cell的bank_allnum + Araay[15].m3的cell的nbank_allnum
      m3 = Araay[12].m3的id的bank_orgnum + Araay[13].m3的id的nbank_orgnum + Araay[14].m3的cell的bank_orgnum + Araay[15].m3的cell的nbank_orgnum
      m6c = Araay[16].m6的id的bank_allnum + Araay[17].m6的id的nbank_allnum + Araay[18].m6的cell的bank_allnum + Araay[19].m6的cell的nbank_allnum
      m6 = Araay[16].m6的id的bank_orgnum + Araay[17].m6的id的nbank_orgnum + Araay[18].m6的cell的bank_orgnum + Araay[19].m6的cell的nbank_orgnum
      m12c = Araay[20].m12的id的bank_allnum + Araay[21].m12的id的nbank_allnum + Araay[22].m12的cell的bank_allnum + Araay[23].m12的cell的nbank_allnum
      m12 = Araay[20].m12的id的bank_orgnum + Araay[21].m12的id的nbank_orgnum + Araay[22].m12的cell的bank_orgnum + Araay[23].m12的cell的nbank_orgnum
      this.list[0].instheight = d15
      this.list[1].instheight = m1
      this.list[2].instheight = m3
      this.list[3].instheight = m6
      this.list[4].instheight = m12
      this.list[0].freheight = d15c
      this.list[1].freheight = m1c
      this.list[2].freheight = m3c
      this.list[3].freheight = m6c
      this.list[4].freheight = m12c
    },
    arrayMap(array, string, title, daySize) {
      let from = {
        keyfou: 0,//电话次数
        keytwo: 0,//id   
        jigou: 0,
        // allnum  申请次数
        // orgnum  申请机构数
        Arrays: []
      }
      let num = []
      array.forEach((res) => {
        // console.log(res.keythrName)
        if (res.keyone == string) {
          if (res.keythrName == title) {
            if (res.keyfou == 'orgnum') {
              from.Arrays.push(res)
              for (let i = 0; i < from.Arrays.length; i++) {
                num.push(from.Arrays[i].value)
                num.forEach(elmnt => {
                  from.jigou += parseInt(elmnt)
                })
              }
              num = []
              from.Arrays = []
            }
            if (res.keytwo == "cell" && res.keyfou == "allnum") {//电话
              from.Arrays.push(res)
              for (let i = 0; i < from.Arrays.length; i++) {
                num.push(from.Arrays[i].value)
                num.forEach(elmnt => {
                  from.keyfou += parseInt(elmnt)
                })
              }
              num = []
              from.Arrays = []
            }
            if (res.keytwo == "id" && res.keyfou == "allnum") {//身份证
              from.Arrays.push(res)
              for (let i = 0; i < from.Arrays.length; i++) {
                num.push(from.Arrays[i].value)
                num.forEach(elmnt => {
                  from.keytwo += parseInt(elmnt)
                })
              }
              num = []
              from.Arrays = []
            }

            if (res.keyfivName == daySize) {

              if (res.keytwo == "cell" && res.keyfou == "allnum") {
                from.Arrays.push(res)

                for (let i = 0; i < from.Arrays.length; i++) {
                  num.push(from.Arrays[i].value)
                  num.forEach(elmnt => {
                    from.keyfou += parseInt(elmnt)
                  })
                }
                num = []
                from.Arrays = []
              }
              if (res.keytwo == "id" && res.keyfou == "allnum") {
                from.Arrays.push(res)

                for (let i = 0; i < from.Arrays.length; i++) {
                  num.push(from.Arrays[i].value)
                  num.forEach(elmnt => {
                    from.keytwo += parseInt(elmnt)
                  })
                }
                num = []
                from.Arrays = []
              }

            }
          }
        }
      })
      // console.log("和", from)
      return from
    },
    getTable(days) {
      console.log("qwe",days)
      let Executor = []
      let DateTables = []
      let form = {
        buyCard: "",
        buyName: "",
        buyPhone: "",
        newCost: "",
        id: "",
        productId: "",
        productName: "",
      };
     
      let formc
      let httpUrl = window.location.href
     
      // let httpUrl = "https://pay.fkykj.cn/report?form=IjEzOTA2NTMzNzQxIg%3D%3D"
      if(getCookie("buyPhone") != ''  || localStorage.getItem("buyPhone")!=''){
        getCookie("buyPhone") == ""?form.buyPhone = localStorage.getItem("buyPhone"):form.buyPhone = getCookie("buyPhone")
      }
      // console.log(form.buyPhone )
      if( form.buyPhone == null){
        // console.log("年后",form)
        var str = new RegExp("%3D", "g");
        httpUrl = httpUrl.replace(str, "");
        httpUrl = httpUrl.split("=")[1] + "";
        formc = window.atob(httpUrl);
        formc = JSON.parse(formc);
        console.log("年后",formc)
        if(typeof formc != "object"){
          form.buyPhone = formc
          
        }
        else{
          form = formc
        }
      } 
      this.buyPhone = form.buyPhone
      this.$http.post("br-risk-strategy/selectByPhone", {buyPhone:form.buyPhone}).then((res) => {
        console.log(res)
        if (res.code == 200) {
          localStorage.removeItem("buyPhone")
          this.myMixintable = res.data
          this.myMixintable.手机号 = this.myMixintable.手机号.substring(7);
          this.myMixintable.身份证号 = this.myMixintable.身份证号.substring(14)
          // console.log(this.myMixintable)
          this.myMixintable.线下消费分期分数=this.myMixintable.线下消费分期分数==""?'无': JSON.parse(this.myMixintable.线下消费分期分数)
          this.product = JSON.parse(this.myMixintable.借贷意向验证产品详细JSON)

          // this.myMixintable.借贷意向验证产品详细JSON
          this.$set(this, 'myMixintable', res.data);
          let dateTime1 = res.data.生成时间
          var subStr = new RegExp('-', 'g');
          dateTime1 = dateTime1.replace(subStr, "/");
          this.dataTime(dateTime1)
          this.statusicon = res.data.信用结果
          this.details = res.data.要素检查
          this.details[1].name_rule = "身份证二要素验证"
          this.details[0].name_rule = "手机三要素验证-移动/联通/电信"
          res.data.手机三要素产品信息 == null ? this.details[0].weight = 0 : this.details[0].weight = res.data.手机三要素产品信息.result
          res.data.身份证二要素产品信息 == null ? this.details[1].weight = 0 : this.details[1].weight = res.data.身份证二要素产品信息.RESULT
          let id = this.myMixintable.号码归属地JSON.id.city == undefined ? "无" : this.myMixintable.号码归属地JSON.id.province + this.myMixintable.号码归属地JSON.id.city
          let cell = this.myMixintable.号码归属地JSON.cell.city == undefined ? "无" : this.myMixintable.号码归属地JSON.cell.province + this.myMixintable.号码归属地JSON.cell.city
          this.details[1].Subtitle = " 身份证&姓名:" + id
          this.details[0].Subtitle = " 手机号归属:" + cell
          this.Operator = res.data.运营商核验
          if (this.operation = this.myMixintable.手机在网时长产品信息 == null) {
            this.Operator[0].costTime = "无"
            this.Operator[1].costTime = '无'
            this.Operator[2].costTime = '无'
            this.Operator[0].name_rule = "手机在网时长"
            this.Operator[1].name_rule = "手机信息验证"
            this.Operator[2].name_rule = "身份证信息验证"
            this.Operator[2].verdict = '无'
            this.Operator[0].Phone = " 手机在网时长"
            this.Operator[1].Phone = " 手机在网状态"
            this.Operator[2].Phone = " 身份证号"
          } else {
            this.operation = this.myMixintable.手机在网时长产品信息
            this.Operator[0].costTime = this.myMixintable.手机在网时长产品信息.costTime
            this.Operator[1].costTime = this.myMixintable.身份证二要素产品风险结果.weight == 0 ? "正常" : "不正常"
            this.Operator[2].costTime = '***********' + res.data.身份证号
            this.Operator[0].name_rule = "手机在网时长"
            this.Operator[1].name_rule = "手机信息验证"
            this.Operator[2].name_rule = "身份证信息验证"
            this.Operator[2].verdict = this.myMixintable.身份证二要素产品风险结果.verdict
            this.Operator[0].Phone = " 手机在网时长"
            this.Operator[1].Phone = " 手机在网状态"
            this.Operator[2].Phone = " 身份证号"
          }
          res.data.借贷意向验证产品风险结果 == undefined ? this.Lendingintention[0].title = "借贷意向验证产品" : this.Lendingintention[0].title = res.data.借贷意向验证产品风险结果.name_rule
          // this.Lendingintention[0].title = res.data.借贷意向验证产品风险结果.name_rule;
          Executor = res.data.法院被执行人限高产品详情
          this.Executor = Executor
          let Speciallist = res.data.特殊名单验证产品详情
          this.order = this.slices(Speciallist, 20)
          this.information = res.data.法院判决文书产品详情
          if (res.data.借贷意向验证产品详细 == undefined) {
            this.Arraytype = []
            this.Arrayinstitution = []
            this.Arrayspecial = []
            this.Arrayproduct = []
          } else {
            this.Arraytype = res.data.借贷意向验证产品详细.本人在各机构
            this.Arrayinstitution = res.data.借贷意向验证产品详细.本人在本机构
            this.Arrayspecial = res.data.借贷意向验证产品详细.本人在特殊期间
            this.Arrayproduct = res.data.借贷意向验证产品详细.本人在各产品
          }

          let banArray = [
            { keyone: "d7", bank_selfnum: null, nbank_selfnum: null, keytwo: "id" ,keythr: "oth"},
            { keyone: "d7", bank_selfnum: null, nbank_selfnum: null, keytwo: "cell" ,keythr: "oth"},
            { keyone: "d15", bank_selfnum: null, nbank_selfnum: null, keytwo: "id" ,keythr: "oth"},
            { keyone: "d15", bank_selfnum: null, nbank_selfnum: null, keytwo: "cell" ,keythr: "oth"},
            { keyone: "m1", bank_selfnum: null, nbank_selfnum: null, keytwo: "id" ,keythr: "oth"},
            { keyone: "m1", bank_selfnum: null, nbank_selfnum: null, keytwo: "cell",keythr: "oth" },
            { keyone: "m3", bank_selfnum: null, nbank_selfnum: null, keytwo: "id" ,keythr: "oth"},
            { keyone: "m3", bank_selfnum: null, nbank_selfnum: null, keytwo: "cell" ,keythr: "oth"},
            { keyone: "m6", bank_selfnum: null, nbank_selfnum: null, keytwo: "id" ,keythr: "oth"},
            { keyone: "m6", bank_selfnum: null, nbank_selfnum: null, keytwo: "cell" ,keythr: "oth"},
            { keyone: "m12", bank_selfnum: null, nbank_selfnum: null, keytwo: "id" ,keythr: "oth"},
            { keyone: "m12", bank_selfnum: null, nbank_selfnum: null, keytwo: "cell" ,keythr: "oth"},
          ]
          banArray[0].bank_selfnum = this.myMixintable.机构数和申请次数[0].d7的id的bank_selfnum
          banArray[0].nbank_selfnum = this.myMixintable.机构数和申请次数[1].d7的id的nbank_selfnum
          banArray[1].bank_selfnum = this.myMixintable.机构数和申请次数[2].d7的cell的bank_selfnum
          banArray[1].nbank_selfnum = this.myMixintable.机构数和申请次数[3].d7的cell的nbank_selfnum
          banArray[2].bank_selfnum = this.myMixintable.机构数和申请次数[4].d15的id的bank_selfnum
          banArray[2].nbank_selfnum = this.myMixintable.机构数和申请次数[5].d15的id的nbank_selfnum
          banArray[3].bank_selfnum = this.myMixintable.机构数和申请次数[6].d15的cell的bank_selfnum
          banArray[3].nbank_selfnum = this.myMixintable.机构数和申请次数[7].d15的cell的nbank_selfnum
          banArray[4].bank_selfnum = this.myMixintable.机构数和申请次数[8].m1的id的bank_selfnum
          banArray[4].nbank_selfnum = this.myMixintable.机构数和申请次数[9].m1的id的nbank_selfnum
          banArray[5].bank_selfnum = this.myMixintable.机构数和申请次数[10].m1的cell的bank_selfnum
          banArray[5].nbank_selfnum = this.myMixintable.机构数和申请次数[11].m1的cell的nbank_selfnum
          banArray[6].bank_selfnum = this.myMixintable.机构数和申请次数[12].m3的id的bank_selfnum
          banArray[6].nbank_selfnum = this.myMixintable.机构数和申请次数[13].m3的id的nbank_selfnum
          banArray[7].bank_selfnum = this.myMixintable.机构数和申请次数[14].m3的cell的bank_selfnum
          banArray[7].nbank_selfnum = this.myMixintable.机构数和申请次数[15].m3的cell的nbank_selfnum
          banArray[8].bank_selfnum = this.myMixintable.机构数和申请次数[16].m6的id的bank_selfnum
          banArray[8].nbank_selfnum = this.myMixintable.机构数和申请次数[17].m6的id的nbank_selfnum
          banArray[9].bank_selfnum = this.myMixintable.机构数和申请次数[18].m6的cell的bank_selfnum
          banArray[9].nbank_selfnum = this.myMixintable.机构数和申请次数[19].m6的cell的nbank_selfnum
          banArray[10].bank_selfnum = this.myMixintable.机构数和申请次数[20].m12的id的bank_selfnum
          banArray[10].nbank_selfnum = this.myMixintable.机构数和申请次数[21].m12的id的nbank_selfnum
          banArray[11].bank_selfnum = this.myMixintable.机构数和申请次数[22].m12的cell的bank_selfnum
          banArray[11].nbank_selfnum = this.myMixintable.机构数和申请次数[23].m12的cell的nbank_selfnum
          let arraysnum = [this.myMixintable.机构数和申请次数]
          this.Treeviews(arraysnum)

          this.$set(this, 'dataList', [banArray, this.Arraytype, this.Arrayspecial, this.Arrayproduct])
          // console.log(this.dataList, 'Arrays');
          this.information.forEach(element => {
            element.status = 0
          });
          var arrs = res.data.运营商核验
          arrs.forEach(res => {
            
            if (res != undefined && res.verdict == "高风险") {
              this.from.value6.push(res)
            }
          })
          arrs = []
          var arrs = [this.myMixintable.法院被执行人限高产品风险结果, this.myMixintable.法院判决文书产品风险结果, this.myMixintable.法院信息产品风险结果, this.myMixintable.特殊名单验证产品详情结果]
          arrs.forEach(res => {
        
            if (res != undefined && res.verdict == "高风险") {
              this.from.value1.push(res)
            }
          })
          arrs = []
          arrs = [this.myMixintable.偿债压力指数产品风险结果, this.myMixintable.团伙欺诈排查产品风险结果]
          arrs.forEach(res => {
            
            if (res != undefined && res.verdict == "高风险") {
              this.from.value2.push(res)

            }
          })
          arrs = this.details
          arrs.forEach(res => {
            if (res.verdict == "高风险") {
              this.from.value3.push(res)
            }
          })
          res.data.法院信息产品详情 == undefined ? this.Dishonestperson = "无" : this.Dishonestperson = res.data.法院信息产品详情.entryList
          if (this.Dishonestperson != "无") {
            this.Dishonestperson.forEach(element => {
              if (element.verdict == "高风险") {
                this.from.value1 = element.verdict
              }
              element.status = 0
            });
          }
        } else if (res.code == 500) {
          Toast({ message: "链接失效", duration: 2000 })
          wx.miniProgram.redirectTo


        } else {
          Toast({ message: res.msg, duration: 2000 })
        }

      });
    },

  }
}