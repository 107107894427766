<template>
  <div style="background: #f9f9f9;position: relative;bottom: 10px;">
    <div class="flex_s boxer" v-for="(item,index) in table" :key="index" @click="gopick(item)">
      <div style="margin:0 20px">
        <div>姓名:{{item.buyName}}</div>
        <div>类型:资信报告</div>
        <div>电话:{{item.buyPhone}}</div>
        <div>下单时间:{{item.createTime}}</div>
      </div>
    </div>
    <div class="flex_v" style="height: 700px;color: gray;" v-show="table.length== 0">没有记录哦~</div>
  </div>
</template>

<script>
import { Base64 } from "js-base64";
export default {
  data(){
    return{
      table:[],
     requerbox: {
        page: 1,
        pageSize: 10,
        conditions: [
          { name: "buyPhone", type: "eq", value: "" },
          { name: "buyCard", type: "lk", value: "" },
          // { name: "parentName", type: "lk", value: "" },
          // { name: "parentPhone", type: "eq", value: "" },
        ],
      },
    }
  },
  created(){
     let form = JSON.parse(this.$route.query.form);
    this.requerbox.conditions[0].value = form.buyPhone
    this.requerbox.conditions[1].value = form.buyCard
    this.chax()
  },
  methods:{
      chax(form){
        this.$http.post("/order/list", this.requerbox).then((res) => {
          if(res.code == 200){
            this.table = res.data
          }
        })
      },
      gopick(item){
       window.location.href =  "https://pay.fkykj.cn/report?form="+Base64.encode(JSON.stringify(item.buyPhone))
         
      }
  }
};
</script>
 
<style>
.boxer{
  width: 90%;
 height: 100px;
  font-size: 15px;
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
  margin: 0px auto;
  position: relative;
  top: 10px;
  margin-top: 10px;
  background:#ffffff;
  border-radius: 15px;
}
</style>