<template>
  <div class="body">
    <header class="flex" style="background: #ffffff">
      <div>
        <img
          src="../assets/icons/leftarray.png"
          alt=""
          class="icon"
          @click="go"
        />
      </div>
      <div></div>
    </header>
    <div class="prices flex_c">
      <div>
        <div>打赏金额</div>
        <div class="font price" style="margin: 10px 0">{{ form.newCost }}</div>
      </div>
    </div>

    <div class="zfbox">
      <div style="margin: 0 10px">打赏方式</div>
      <div class="box flex_b" v-for="(item, index) in nav" :key="index">
        <div class="flex margin_left_35">
          <img :src="item.img" mode="" class="icon" style="position: relative;top: 0px;" />
          <div class="font_style" style="margin: 0 15px">
            {{ item.title }}
          </div>
        </div>
        <div @click="currents(index)">
          <input
            name="Fruit"
            type="radio"
            value=""
            style="width: 18px; height: 18px"
          />
        </div>
      </div>
       <div style="color:#ad001a;font-size: 0.7em;width: 95%;margin: 0 auto;"> 提示:您如果是苹果手机用户，支付完成后，请切换到浏览器中，<br>点击“我已付款，返回查看订单”即可查询资信报告</div>
    </div>
   
    <div class="flex_v" style="margin: 60px 0">
      <button
        type="default"
        class="bt"
        style="background: #ad001a; color: #ffffff"
        @click="Payment"
      >
        支付
      </button>
      <div class="flex" v style="width: 90%">
        <div style="text-align: left" class="raybox" @click="gart">
          <div class="ray" v-show="!states"></div>
        </div>
        <div
          style="
            text-align: left;
            font-size: 16px;
            font-weight: 400;
            margin: 5px 5px;
          "
          class="font"
          @click="Needknow"
        >
          用户需知
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import zhibao from "../assets/icons/zhifubao@2x.png";
import wx from "../assets/icons/wx@2x.png";
import { Toast } from "vant";
import { setCookie, getCookie } from "../components/cooike";
// import E from "../components/clipboard.js-master/dist/clipboard";
export default {
  data() {
    return {
      nav: [
        {
          title: "支付宝",
          img: zhibao,
        },
        // {
        //   title: "微信",
        //   img: wx,
        // },
      ],
      states: 1,
      form: {},
      current: null,
    };
  },
  created() {
    let form = this.$route.query.form;
    // console.log(form);
    this.form = JSON.parse(form);
    // console.log(this.form);
  },
  methods: {
    currents(index) {
      this.current = index;
    },
    Needknow() {
      this.$router.push({ path: "../Needknow" });
    },
    gart() {
      if (this.states == 0) {
        this.states = 1;
      } else {
        this.states = 0;
      }
    },
    go() {
      this.$router.go(-1);
    },

    Payment() {
      let form = this.form;
      var wx = "";
      let r = /\"(.*?)\"/g;
      if (this.states == 1) {
        Toast("勾选用户需知");
        return false;
      } else if (this.current == null) {
        Toast("勾选支付类型");
        return false;
      }
      if (this.current == 0) {
         let buyPhone = form.buyPhone;
        this.$http.post("/pay/zfbCreateOrder", form).then((res) => {
           buyPhone = form.buyPhone;
          setCookie("buyPhone", buyPhone, 7);
          localStorage.setItem("buyPhone", buyPhone);
          this.$router.push({ path: "/Home", query: { htmlData: res } });
          // Toast({ message: "支付成功", duration: 2000 });
        });
      } else {
        this.$http.post("/pay/wxCreateOrder", form).then((res) => {
          wx = res.match(r);
          wx[1] = wx[1].substring(1, wx[1].length - 1);
          buyPhone = form.buyPhone;
          setCookie("buyPhone", buyPhone, 7);
          localStorage.setItem("buyPhone", buyPhone);
          this.$router.push({
            path: "/Home",
            query: { htmlData: wx[1], status: 2 },
          });
          //  Toast({ message: "支付成功", duration: 2000 });
        });
      }
    },
    radioChange: function (evt) {
      for (let i = 0; i < this.nav.length; i++) {
        if (this.nav[i].value === evt.detail.value) {
          this.current = i;
          break;
        }
      }
    },
  },
};
</script>

<style lang="less">
// .body {
//   position: absolute;
//   left: 0;
//   right: 0;
//   top: 0;
//   bottom: 0;
//   background: #f9f9f9;
// }
.raybox {
  width: 14px;
  height: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  border: 1px solid #a5a5a5;
  .ray {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: #ad001a;
  }
}

.flex_v {
  display: flex;
  // margin: 70px 0;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.prices {
  width: 100%;
  height: 160px;
  background: #ffffff;
  text-align: center;
  /* margin: 70px auto; */
}
.bt {
  width: 90%;
  height: 40px;
  background: #ad001a;
  opacity: 1;
  color: #fff;
  font-size: 15px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #ffffff;
  opacity: 1;
  border: none;
  border-radius: 4px;
}
.font_style {
  font-size: 17px;
  font-weight: 600;
}
.font {
  font-size: 20px;
  font-weight: 600;
}

.icon {
  width: 26px;
  height: 26px;
}

.zfbox {
  position: relative;
  top: 30px;
}
.box {
  margin: 10px auto;
  height: 52px;
  padding: 0 10px;
  background: #ffffff;
  opacity: 1;
  box-shadow: 0px 1px 1px 0px #a5a5a5;
  border-radius: 0px;
}
</style>