<template>
  <div class="flex_v page">
    <div class="Mantle flex_c" v-show="Mantlebox ">
        请在浏览器查看~
    </div>
    <div class="form">
      <div>
        <input
          class="inputs"
          name="phone"
          maxlength="11"
         onkeyup="value=value.replace(/[^\d\.]/g,'')"
          v-model="formData.phone"
          placeholder="请输入手机号"
        />
        <input
          class="inputs"
          name="name"
          v-model="formData.name"
          placeholder="请输入用户名"
        />
        <div class="flex_b" style="margin:-35px 0">
          <input
            class="Yinput"
            name="code"
            v-model="formData.code"
            placeholder="请输入验证码"
          />
          <van-button class="bt" @click="handle">{{ contTaxt }}</van-button>
        </div>
        <input
          class="inputs"
          name="psw"
          v-model="formData.psw"
          placeholder="请输入密码"
        />
        <input
          class="inputs"
          name="newpsw"
          v-model="newpsw"
          placeholder="请再次输入密码"
        />
        <div class="text flex">
          <div class="radios"><span class="icon">!</span></div>
          <!-- <span style="margin: 0 5px">密码长度8位，包含数字</span> -->
        </div>
        <div>
          <van-button
            class="register"
            block
            native-type="submit"
            @click="onSubmit"
            >注册</van-button
          >
        </div>
      </div>
      <!-- <div class="flex_c">
        <img src="../assets/icons/dianji@2x.png" alt="" />
        <div class="border_top">
          <a href="#" style="color: #ad001a" @click="goTob">点击下载APP登录</a>
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
import { Toast } from "vant";
import { Base64 } from "js-base64";
export default {
  data() {
    return {
      contTaxt: "获取验证码",
      times: "",
      checked: "",
      newpsw: null,
      Mantlebox:false,
      formData: {
        phone: null,
        name: "",
        parentId: "",
        psw: null,
        code: null,
        status: 1,
      },
    };
  },
  watch: {
    contTaxt(resolve, object) {
      // console.log(resolve);
      if (resolve <= 0) {
        clearInterval(this.times);
        this.contTaxt = "获取验证码";
      }
    },
  },
  created() {
    var ua = window.navigator.userAgent.toLowerCase();
    if (ua.match(/MicroMessenger/i) == "micromessenger") {
       this.Mantlebox = true
    } else {
      this.Mantlebox = false
      console.log(this.Mantlebox)
      console.log("不是微信浏览器");
    }
    let httpUrl = window.location.href;
    // let httpUrl = "https://pay.fkykj.cn/Signin?from=IjE1Nzg2NzQzNjg3MTgwODIwNTAi"
    var str = httpUrl;
    var reg = new RegExp("%3D", "g");
    var a = str.replace(reg, "");
    httpUrl = a;
    httpUrl = httpUrl.split("=")[1] + "";
    let form = Base64.decode(httpUrl);
    this.formData.parentId = JSON.parse(form);

  },
  methods: {
    onSubmit() {
      let num = this.formData.psw;
      if (this.formData.phone == "") {
        Toast("请输入手机号");
        return false;
      }
      if (this.formData.name == "") {
        Toast("请输入用户名");
        return false;
      } else if (this.formData.code == "") {
        Toast("请输入验证码");
        return false;
      } else if (this.formData.psw == "") {
        Toast("请输入密码");
        return false;
      } else if (this.formData.psw != this.newpsw) {
        Toast("密码不一致");
        return false;
      } else {
        this.login();
      }
    },
    // goTob() {
    //   window.location.href = "http://app.fkykj.cn/zqlr"
    // },
    verification(min) {
      let time = 60;
      return new Promise((resolve, object) => {
        if (this.contTaxt == "获取验证码" && this.formData.phone != "") {
          this.times = setInterval(() => {
            this.contTaxt = time--;
          }, min);
          resolve(this.codes());
        } else {
          Toast("请输入手机号");
        }
      });
    },
    async handle() {
   
      setTimeout(() => {
        console.log("完成");
      }, 1000);
      await this.verification(1000);
    },
    login() {
      this.$http.post("/app-user/regis", this.formData).then((res) => {
        if (res.code == 200) {
          Toast("注册成功,跳转中");
            setTimeout(()=>{
              this.$router.push({path:"/download"})
            },1500)
        } else {
          Toast(res.msg);
        }
      });
    },
    codes() {
      this.$http.post("/send/sendCode", this.formData).then((res) => {
        if (res.code == 200) {
          Toast(res.msg);
        } else {
          Toast(res.msg);
          clearInterval(this.times);
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.page {
  // height: 100vh;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
}
.radios {
  width: 14px;
  height: 14px;
  background: #d6d6d6;
  opacity: 1;
  text-align: center;
  position: relative;
  border-radius: 50%;
}
.text {
  width: 95%;
  margin-top: 10px;
  text-align: left;
  font-size: 12px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #444444;
  opacity: 1;
}
.register {
  width: 100%;
  margin: 15px 0;
  color: #ffffff;
  height: 48px;
  background: #ad001a;
  opacity: 1;
  border-radius: 4px;
}
.inputs {
  width: 98%;
  height: 48px;
  text-indent: 15px;
  font-size: 15px;
  border: 1px solid rgb(240, 235, 235);
  margin: 10px 0;
  background: #f9f9f9;
  opacity: 1;
  border-radius: 4px;
}
.form {
  width: 90%;
}
.border_top {
  border-bottom: 1px solid #ad001a;
}
.yzbox {
  width: 95%;
}
.Yinput {
  width: 230px;
  height: 48px;
  text-indent: 15px;
  font-size: 15px;
  border: 1px solid rgb(240, 235, 235);
  background: #f9f9f9;
  opacity: 1;
  border-radius: 4px;
}
.bt {
  width: 110px;
  height: 48px;
  font-size: 14px;
  background: #ad001a;
  border: 1px solid #ad001a;
  color: #ffffff;
}
.Mantle {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(rgb(5, 5, 5), 0.5);
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #ffffff;
  z-index: 9;
  .box{
    // width:190px;
    // height: 100px;
    margin: 40px 20px;
    z-index: 2;
    // border: 1px solid rgba(rgb(4, 4, 4), 0.001);
    background-color: rgba(rgb(4, 4, 4), 0.001);
    border:none;
    // background: pink;
  }
  .img{
    position: relative;
    left: 70px;
    //  background-color: rgba(rgb(5, 5, 5), 0.5);
  }
}
.icon {
  position: relative;
  bottom: 1px;
}
</style>
