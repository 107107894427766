<template>
  <div class="about flex_v">
   <div class="flex_s" style="width:95%;padding: 10px;">
    <img src="../assets/icons/leftarray.png" alt="" width="20" @click="goiump">
   </div>
    <div class="Mantle flexe" v-show="Mantlebox">
      <div class="box">
        <img src="../assets/icons/jiantou2.png" alt="" width="80" class="img" style="z-index: 9;border: 0;"/>
        <div>亲,请打开浏览器查看哦~</div>
      </div>
    </div>
    <div class="flex_c form">
      <div action="">
        <div>
          <input
            type="text"
            class="input"
            name="buyPhone"
            placeholder="请输入您的手机号"
            v-model="formData.buyPhone"
          />
        </div>
        <div>
          <input
            type="text"
            class="input"
            name="buyCard"
            placeholder="请输入身份证后6位"
            v-model="formData.buyCard"
          />
        </div>
        <van-button class="bt" @click="text" block>查询历史记录</van-button>
      </div>
    </div>
  </div>
</template>
<script>
import { Base64 } from "js-base64";
import { Toast } from "vant";
export default {
  data() {
    return {
      formData: {
        buyName: "", //买家
        buyPhone: "",
        buyCard: "",
        id: "",
        Mantlebox:false,
        productId: null,
        productName: "",
        newCost: null,
        userId: null,
      },
    };
  },
 
  created() {
 
  },
  methods: {
   
    text() {
      let {  buyPhone, buyCard } = this.formData;
      if (buyPhone == "") {
        Toast("手机号不能为空");
      } else if (buyCard == "") {
        Toast("身份证不能为空");
      }else {
        this.goquery();
      }
    },
    goiump(){
      this.$router.go(-1)
    },
    goquery() {
      this.$router.push({
        path: "/Histlist",
        query: { form: JSON.stringify(this.formData)},
      });
    },
  },
};
</script>
<style lang="less">
.bt {
  width: 300px;
  height: 40px;
  margin: 50px auto;
  background: #ad001a;
  opacity: 1;
  color: #fff;
  font-size: 15px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #ffffff;
  opacity: 1;
  border: none;
  border-radius: 4px;
}
.Mantle {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(rgb(5, 5, 5), 0.5);
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #ffffff;
  z-index: 9;
  .box{
    // width:190px;
    // height: 100px;
    margin: 40px 20px;
    z-index: 2;
    // border: 1px solid rgba(rgb(4, 4, 4), 0.001);
    background-color: rgba(rgb(4, 4, 4), 0.001);
    border:none;
    // background: pink;
  }
  .img{
    position: relative;
    left: 70px;
    //  background-color: rgba(rgb(5, 5, 5), 0.5);
  }
}
.about {
  width: 100%;
  height: 100%;
}
.form {
  margin: 40% 0;
}
.input {
  width: 300px;
  height: 50px;
  font-size: 15px;
  text-indent: 10px;
  margin: 10px auto;
  background: #e9e6e6;
  border: 1 solid #f5eded;
  border-radius: 4px;
  border: 0px;
  opacity: 1;
}
</style>
