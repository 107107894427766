<template>
  <div>
    <div class="flex_a">
      <div
        v-for="(item, index) in list"
        :key="item.id"
        :class="selected == item.id ? 'nav' : 'grays'"
        @click="handleSelectedDate(item)"
      >
        {{ item.name }}
      </div>
    </div>
    <div >
      <div style="padding: 10px" class="font_style">本⼈在本机构申请的次数</div>
				<div v-if="content1 != ''">
					<div 
					class="flex_b hang" 
					style="padding: 10px; color: #444444"
				>
					<div>银行</div>
					<div class="flex">
						<div>{{content1[0].bank_selfnum}}/{{ content1[1].nbank_selfnum }}</div>
					</div>
				</div>
				</div>
				<div v-if="content1 != ''">
					<div 
					class="flex_b hang" 
					style="padding: 10px; color: #444444"
				>
					<div>非银</div>
					<div class="flex">
						 <!-- <div style="position: relative;right: 55px;" class="byOrgNum">{{ item.byOrgNum || '--' }}</div> -->
						<div>{{content1[0].nbank_selfnum}}/{{ content1[1].nbank_selfnum }}</div>
					</div>
				</div>
				</div>
				<div v-if="content1 == ''" class="flex_c" style="padding:50px 0"><span>数据无命中</span></div>
    </div>
	
		<div>
		  <div style="padding: 10px" class="font_style">本⼈在各业务类型申请的机构数、次数</div>
		  <div class="flex_b nav">
		    <div>类型</div>
		    <div class="flex_b" style="width: 100px">
		      <div>机构数</div>
		      <div>次数</div>
		    </div>
		  </div>
		  <div v-if="content2 != ''">
		    <div
		      class="flex_b hang"
		      style="padding: 10px; color: #444444"
		      v-for="(item, index) in content2"
		      :key="`${item.id}-${selected}`"
		    >
		      <div>{{ item.name }}</div>
		      <div class="flex_b" style="width: 84px">
		        <div>	{{ item.byOrgNum || '-'}}{{ item.byOrgNum ? '/' : '' }}{{ item.OrgNumId  || '-' }}</div>
		        <div>
							{{ item.byIdentityNum || '-'}}{{ item.byIdentityNum ? '/' : '' }}{{ item.byPhoneNum  || '-' }}
						</div>
		      </div>
		    </div>
		    <div>
		      <div class="gray" style="margin: 10px 0">
		        1.
		        取值结果展⽰：按⾝份证号查询命中次数/按⼿机号查询命中次数。如：”1/2”表⽰按⾝份证号查询命中1次，按⼿机号查询命中2次。
		        2. 取值为”空”
		        “N”——“空”，⽆申请记录，⽤“-”符号表⽰；0本机构⽆申请记录但其他银⾏机构或⾮银机构有申请；N申请记录详情。
		      </div>
		    </div>
		  </div>
		 <div v-if="content2 == ''" class="flex_c" style="padding:50px 0"><span>数据无命中</span></div>
		</div>
		<div>
		  <div style="padding: 10px" class="font_style">本⼈在特殊时间段申请的机构数、次数</div>
		  <div>
		    <div 
					class="flex_b hang" 
					style="padding: 10px; color: #444444"
					v-for="(item, index) in content3"
					:key="`${item.id}-${selected}`"
				>
					<div>{{ item.name }}</div>
		      <div class="flex_b" style="width: 84px">
		        <div>	{{ item.byOrgNum || '-'}}{{ item.byOrgNum ? '/' : '' }}{{ item.OrgNumId  || '-' }}</div>
		        <div>
		      		{{ item.byIdentityNum || '-'}}{{ item.byIdentityNum ? '/' : '' }}{{ item.byPhoneNum  || '-' }}
		      	</div>
		      </div>
		    </div>
		  </div>
		  <div v-if="content3 == ''" class="flex_c" style="padding:50px 0"><span>数据无命中</span></div>
		</div>
		<div>
		  <div style="padding: 10px" class="font_style">本人在各产品类型申请的机构数、次数</div>
		  <div class="flex_b nav">
		    <div>类型</div>
		    <div class="flex_b" style="width: 100px">
		      <div>机构数</div>
		      <div>次数</div>
		    </div>
		  </div>
		  <div>
				<div
					class="flex_b hang" 
					style="padding: 10px; color: #444444"
					v-for="(item, index) in content4"
					:key="`${item.id}-${selected}`"
				>
					<div>{{ item.name }}</div>
				  <div class="flex_b" style="width: 84px">
				  <div>	{{ item.byOrgNum || '-'}}{{ item.byOrgNum ? '/' : '' }}{{ item.OrgNumId  || '-' }}</div>
				    <div>
				  		{{ item.byIdentityNum || '-'}}{{ item.byIdentityNum ? '/' : '' }}{{ item.byPhoneNum  || '-' }}
				  	</div>
				  </div>
				</div>
				 <div v-if="content4 == ''" class="flex_c" style="padding:50px 0"><span>数据无命中</span></div>
		    <div>
		      <div class="gray" style="margin: 10px 0">
		        1.
		        取值结果展⽰：按⾝份证号查询命中次数/按⼿机号查询命中次数。如：”1/2”表⽰按⾝份证号查询命中1次，按⼿机号查询命中2次。
		        2. 取值为”空”
		        “N”——“空”，⽆申请记录，⽤“-”符号表⽰；0本机构⽆申请记录但其他银⾏机构或⾮银机构有申请；N申请记录详情。
		      </div>
		    </div>
		  </div>
		 
		</div>
	</div>
</template>

<script>
export default {
  props: {
    table: Array,
  },
  setup(props) { },
  mounted() {
    // this.dataSuju()

  },
  data() {
    return {
      selected: 'm12',
			tab1: ['银行', '非银'],
			tab2: ['传统银行', '持牌网络小贷机构', '持牌汽车金融机构', '持牌小贷机构', '持牌消费金融机构', '持牌融资租赁机构', '网络零售银行', '其他'],
			tab3: ['银行-夜间', '非银-夜间', '银行-周末', '非银-周末'],
			tab4: ['线上小额现金贷', '线上现金分期', '信用卡（类信用卡）', '线下现金分期', '线下消费分期', '汽车金融', '线上消费分期', '其他'],
			list: [
        { name: "近7天", id: "d7" },
        { name: "近15天", id: "d15" },
        { name: "近一个月", id: "m1" },
        { name: "近3个月", id: "m3" },
        { name: "近6个月", id: "m6" },
        { name: "近12个月", id: "m12" },
      ]
    };
  },

  methods: {
    dataSuju() {
    
    },
		handleSelectedDate(item) {
			this.selected = item.id;
			console.log(this.selected)
		},
		handleCount(list, condition, tabs) {
			let arr = [];
			let selected = this.selected;
			if(list && list.length) {
				tabs.forEach(item => {
					let byOrgNum = list.reduce((tol, cur) => {
						if(cur.keyfou == 'orgnum' && cur.keytwo == 'id' && eval(condition) && cur.keyone == selected) {
							return tol = cur.value
						}
						return tol
					}, 0)
					let OrgNumId = list.reduce((tol, cur) => {
						if(cur.keyfou == 'orgnum' && cur.keytwo == 'cell'  && eval(condition) && cur.keyone == selected) {
							return tol = cur.value 
						}
						return tol
					}, 0)
					let byIdentityNum = list.reduce((tol, cur) => { 
						if(cur.keyfou == 'allnum' && cur.keytwo == 'id' && eval(condition) && cur.keyone == selected) {
							return tol += parseInt(cur.value)
						}
						return tol
					}, 0)
					
					let byPhoneNum = list.reduce((tol, cur) => {
						if(cur.keyfou == 'allnum' && cur.keytwo == 'cell' && eval(condition) && cur.keyone == selected) {
							return tol += parseInt(cur.value)
						}
						return tol
					}, 0)
					// let nbank = list.reduce((tol, cur) => {
					// 	if(cur.bank_selfnum !=undefined &&cur.nbank_selfnum !=undefined && cur.keytwo == 'id' && cur.keyone == selected) {
					// 		console.log("值",cur)
					// 		//}}/{{ item.byPhoneNum 
					// 	}
					// 	return tol
					// }, 0)
					arr.push({
						name: item,
						byOrgNum,
						byIdentityNum,
						OrgNumId,
						id: Math.random(),
						byPhoneNum
					})
				})
			}
			
			return arr
		}
  },
	computed: {
		content1({ table, selected, tab1 }) {
			let list = table?.[0] || [];
			let array = []
			list.forEach((res,index)=>{
				if(res.keyone == this.selected){
						array.push(res)
				}
			})
			return array
		},
		content2({ table, selected, tab2 }) {
			let list = table?.[1] || [];
			let condition = "(cur.keyfivName == item ) || ( cur.keythr=='oth' && !tabs.includes(cur.keyfivName))";
			let condition1 = "(cur.keyfivName == item && item != '其他') || (item == '其他' && !tabs.includes(cur.keyfivName))";
			return this.handleCount(list, condition, tab2)
			
		},
		content3({ table, selected, tab3 }) {
			let list = table?.[2] || [];
			let condition = "`${cur.keythrName}-${cur.keyfivName}` == item";
			return this.handleCount(list, condition, tab3)
		},
		content4({ table, selected, tab4 }) {
			let list = table?.[3] || [];
			let condition = "cur.keythrName && cur.keythrName.includes(item)";
			return this.handleCount(list, condition, tab4);
		},
	}
};
</script>

<style lang="less">
.nav {
  height: 24px;
  color: #fff;
  padding: 3px 7px;
  line-height: 2;
  font-weight: 400;
  background: #ad001a;
  opacity: 1;
  border-radius: 0px;
}
.grays {
  color: #beb8b8;
}
.font_style{
	font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
	font-weight: 600;
	font-size: 14px;
}
.hang {
  border-bottom: 1px solid #efecec;
}

</style>