<template>
  <div>
    <div style="text-align: center"><h4>报告查询服务协议</h4></div>
    <div style="width: 90%; margin: 0 auto;font-weight: 500;font-size: 14px;">
      本协议是您（以下又称“用户”）在使用本服务时，约定您和深圳市风控眼科技有限公司之间权利义务关系的有效协议。
      在您使用本服务前，请您务必仔细阅读本协议，特别是隐私权保护及授权条款、免除或者限制深圳市风控眼科技有限公司责任的条款、争议解决和法律适用条款。一旦您有对本服务的任何部分或全部的注册、查看、定制、使用等任何使用行为，即视为您已充分阅读、理解并接受本协议的全部内容，并与深圳市风控眼科技有限公司达成本协议。如您对本协议有任何疑问，应向深圳市风控眼科技有限公司客服咨询。如果您不同意本协议的部分或全部约定，您应立即停止使用本服务。您与深圳市风控眼科技有限公司达成本协议后，您承诺接受并遵守本协议的约定，并不得以未阅读本协议的内容或者未获得深圳市风控眼科技有限公司对您问询的解答等理由，主张本协议无效，或要求撤销本协议。在本协议履行过程中，深圳市风控眼科技有限公司可以依其单独判断暂时停止提供、限制或改变本服务，并有权根据自身业务需要修订本协
      议。一旦本协议的内容发生变动，深圳市风控眼科技有限公司将通过平台公布最新的服务协议，不再向您作个别通知。如果您不同意深圳市风控眼科技有限公司对本服务协议所做的修改，您应立即停止使用本服务或通过深圳市风控眼科技有限公司客服与深圳市风控眼科技有限公司联系。如果您继续使用本服务，则视为您接受深圳市风控眼科技有限公司对本协议所做的修改，并应遵照修改后的协议执行。
      <br/><h4>一、服务内容</h4><br>
      本服务向您提供多项个人信息整理服务。您知悉并认可，如您需使用该类服务，必须满足如下所述条件；且您承诺，
      您向深圳市风控眼科技有限公司提请服务申请时，已经满足如下所述条件。
      A.您已注册成为本服务的会员；
      B.您已在服务页面对应框中填写被查询主体的姓名、身份证号、手机号、银行卡号和被查询主体的手机号收到的动态验证码（以下称“被查询主体信息”）；
      C.您确保被查询主体信息是您本人的信息或者被查询主体已授权您本人使用被查询主体信息进行查询（授权内容应包括本条D项所述内容），并且被查询主体已知悉该授权的风险。D.被查询主体不可撤销地授权深圳市风控眼科技有限公司为查询、评估被查询主体的信息状况：a.可以委托合法存续的第
      三方机构收集、查询、验证、使用并提供您或被查询主体的个人信息；b.可以向数据源机构采集您或被查询主体的个人信息；c.可以整理、保存、加工、使用您或被查询主体的个人信息，并向您提供数据报告；d.可以向为您提供服务的第三方商户提供脱敏后的个人信息或数据报告。本条所述的个人信息包括但不限于身份信息、联系方式、职业和居
      住地址等个人基本信息，个人社保、公积金、收入及在商业活动中形成的各类交易记录，个人公共费用缴纳、违法违规信息、财产状况等；
      E.被查询主体已被明确告知提供被查询主体信息并作出D.项授权可能给被查询主体带来的各类损失以及其他可能的不利后果，包括采集上述个人信息对被查询主体信用方面可能产生不良影响以及上述信息被信息使用者依法提供给第三方后被他人不当利用的风险。
      F.您已全额支付相应的查询服务费用；<br/> <h4>二、服务中断或故障</h4><br/>
      您同意，因下列原因导致深圳市风控眼科技有限公司无法正常提供本服务的，深圳市风控眼科技有限公司不承担责任：（1）承载本服务的系统停机维护期间；
      （2）您的电脑、手机软硬件和通信线路、供电线路出现故障的；（3）您操作不当或通过非深圳市风控眼科技有限公司授权或认可的方式使用本服务的；（4）因病毒、木马、恶意程序攻击、网络拥堵、系统不稳定、系统或设备故障、通讯故障、电力故障或政府行为等
      原因；（5）由于黑客攻击、网络供应商技术调整或故障、网站升级、手机运营商系统方面的问题等原因而造成的本服务中
      断或延迟；（6）因台风、地震、海啸、洪水、停电、战争、恐怖袭击等不可抗力之因素，造成本服务系统障碍不能执行业务的。深圳市风控眼科技有限公司不对因使用本服务而对用户造成的间接的、附带的、特殊的、后果性的损失承担任何法律责任；尽管有前款约定深圳市风控眼科技有限公司将采取合理行动积极促使本服务恢复正常。
      <br/><h4>三、信息的使用和保护</h4><br/>
      深圳市风控眼科技有限公司深知您注重个人信息安全和保护，并理解保护被查询主体个人信息的重要性。深圳市风控眼科技有限公司会严格遵守中国关于收集、使用、保存用户个人信息的相关法律法规，尽最大努力采用相应安全技术和管理手
      段保护您或被查询主体的个人信息，防止您或被查询主体个人信息遭受未经授权的访问、适用或泄露、毁损、篡改或
      者丢失。未经您或被查询主体的授权不会向任何第三方提供。
      但您同意，为核实深圳市风控眼科技有限公司的记录、了解用户群体偏好、提高本服务质量、完善本服务的个性化内容及种类、为您提供更优质的服务和用户体验之目的，深圳市风控眼科技有限公司可以短信、电子邮件或其他方式向您推送符合您需求的相关资讯；您使用本服务，即表示您已授
      权深圳市风控眼科技有限公司将您相关信息披露给深圳市风控眼科技有限公司关联公司（关联公司是指关联公司是指直接或间接控制、受控制于本协议一方的任何法律实体，或者与本协议一方共同受控制于另一法律实体的任何法律实体）使用，且深圳市风控眼科技有限公司关联公司仅为了向您提供服务而使用您的相关信息。如深圳市风控眼科技有限公司关联公司使用您的相关信息，则受本协议约束且会按照与深圳市风控眼科技有限公司同等谨慎程度保护您的相关信息。
      深圳市风控眼科技有限公司就下列原因导致的您或被查询主体个人信息的泄露，不承担任何法律责任：<br/>
      <br/>（1）由于您个人原因将本服务的会员账号和密码告知他人或与他人共享深圳市风控眼科技有限公司服务账户，由此导致的与您相关的信息的泄露。<br/>
      （2）您使用第三方提供的服务（包括您向第三方提供的任何个人信息），须受第三方自己的服务条款及个人信息保护协议（而非本协议）约束，您需要仔细阅读其条款。本协议仅适用于深圳市风控眼科技有限公司所提供的服务，并不适用于任何第三方提供的服务或第三方的信息使用规则，深圳市风控眼科技有限公司对任何第三方使用由您提供的信息不承担任何责任。<br/>
      （3）根据相关的法律法规、相关政府主管部门或相关证券交易所的要求提供、公布与您相关的信息。<br/>
      （4）或其他非因深圳市风控眼科技有限公司原因导致的与您相关的信息的泄露。<br/>
      <br/><h4>四. 用户声明与保证</h4><br/>
      （1）您使用本服务的前提是您依照适用的法律，是具有完全民事权利和民事行为能力，能够独立承担民事责任的自然人。<br/>
      （2）您如违反本协议第一条款中的承诺，您可能会对他人造成侵权。如由此给安徽信智有限公司服务有限公司或他<br/>
      人造成损失的，您需依照法律法规规定承担相应的法律责任。<br/>
      （3）如您是18周岁以下的未成年人，您保证在使用本服务前，已取得您的家长或法定监护人的同意。一旦您以任何<br/>
      方式注册、使用本服务的任何部分或全部，即表明您的家长或法定监护人同意您使用本服务并同意本协议的全部内容，同时允许深圳市风控眼科技有限公司依据本协议向您提供相关服务。<br/>
      <h4>五.知识产权保护</h4><br/>
      本服务涉及的文档资料、软件、商标、图案、排版设计等（以下简称“深圳市风控眼科技有限公司”）的著作权、商标以及其他知识产权或权益均为深圳市风控眼科技有限公司享有或深圳市风控眼科技有限公司获得授权使用。用户不得出租、出借、
      拷贝、仿冒、复制或修改深圳市风控眼科技有限公司产品任何部分或用于其他任何商业目的，也不得将深圳市风控眼科技有限公司产品做反向工程、反编译或反汇编，或以其他方式或工具取得深圳市风控眼科技有限公司产品之目标程序或源代码。
      如果用户违反此约定，造成深圳市风控眼科技有限公司及其他任何第三方任何损失的，甲方应予以全额赔偿。<br/>
      <h4>六、违约</h4><br/>
      用户不得利用本服务进行任何损害深圳市风控眼科技有限公司及其他第三方权益的行为，否则深圳市风控眼科技有限公司有权立即终止为该用户提供本服务，并要求用户赔偿损失。由此产生的任何后果由用户自行承担，与深圳市风控眼有限公司无关。
      <br/><h4>七、适用法律</h4><br/>
      本协议条款的解释、效力及纠纷的解决，适用中华人民共和国大陆地区法律法规。如用户和深圳市风控眼有限公司服务有限公司之间发生任何争议，首先应友好协商解决，协商不成的，应将争议提交至深圳市风控眼科技有限公司注册地有管辖
      权的人民法院解决。<br/> <h4>八、问题咨询</h4><br/>
      如您对本协议及本服务有任何问题，请打电话咨询，深圳市风控眼科技有限公司会尽最大努力解决您的问题
      代收代付三方协议 甲方: 甲方手机号: 乙方: 乙方手机号:
      丙方：深圳市风控眼科技有限公司
      为妥善解决甲乙双方、甲丙双方的代收代付渠道服务费问题，甲、乙、丙三方经协商，依法达成如下协议，以资信守：
      三方合作内容如下： 1、乙方推荐甲方使用丙方的APP工具进行风险检测。
      2、乙方为甲方提供中介咨询服务。甲方在使用丙方APP工具并付费时，费用包含两部分内容，一是丙方APP工具的使用费，二是丙方代乙方收取的中介咨询服务费，具体中介咨询服务费根据乙方服务场景而定。甲乙丙三方一致同意：同意丙方代乙方收取甲方中介咨询服务费，同意丙方代甲方将中介咨询服务费通过支付宝或微信的方式付款给乙方。本协议未尽事宜协商解决
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style>
</style>